'use strict';

module.exports = function () {
    $(document).on('mouseenter focusin click', '.recycle-fee-tooltip, .recycle-fee-tooltip-shipping', function (e) {
        e.preventDefault();
        $(this).find('.popover-tooltip').addClass('show');
    });

    $(document).on('mouseleave focusout', '.recycle-fee-tooltip, .recycle-fee-tooltip-shipping', function () {
        $(this).find('.popover-tooltip').removeClass('show');
    });
    $(document).on('mouseenter focusin touchstart', '.shipping-info-tooltip', function (e) {
        $(this).siblings('.popover-tooltip').addClass('d-block');
    });

    $(document).on('mouseleave focusout', '.shipping-info-tooltip', function () {
        $(this).siblings('.popover-tooltip').removeClass('d-block');
    });
};
