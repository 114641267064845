'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var focusHelper = require('core/components/focus');

const escapeKey = 27;
const enterKey = 13;
const spaceKey = 32;

const SELECTORS = {
    header: 'header',
    menuOverlay: '.menu-overlay',
    dropdown: '.dropdown',
    dropdownMenu: '.dropdown-menu',
};

var clearSelection = function (element) {
    $(element).closest('.navbar').find('.navbar-nav').find('.nav-link').attr('tabindex', '0');
    $(element).closest('.navbar').find('.hamburger-menu-bottom').removeClass('d-none');
    $(element).closest('.navbar').find('.locale-selector').removeClass('d-none');
    $(element).closest('.navbar').find('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.navbar').find('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true').addClass('d-none');
    $(element).closest('.navbar').find('.dropdown.show').removeClass('show');
    $('header').removeClass('show-on-top');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $('ul.dropdown-list').attr('aria-hidden', 'true');
    if($('.menu-overlay').length) {
        $('.menu-overlay').remove()
    }
};

/**
 * Hides the active dropdown menu.
 * @param {boolean} hideOverlay - A boolean value indicating whether to hide the overlay.
 */
function hideNavDropdown(hideOverlay) {
    const $activeDropdown = $(SELECTORS.dropdown + '.show');

    if ($activeDropdown.length > 0) {
        $activeDropdown.removeClass('show');
        $('header').removeClass('show-on-top');
        $activeDropdown.find(SELECTORS.dropdownMenu).removeClass('show');
    }

    // remove overlay
    if (hideOverlay && $(SELECTORS.menuOverlay).length) {
        $(SELECTORS.menuOverlay).remove();
    }
}

/**
 * Shows the desktop dropdown menu.
 * @param {string} dropdownTarget - The target dropdown element.
 */
function showDesktopDropdownMenu(dropdownTarget) {
    const $dropdownWrapper = $(dropdownTarget).closest(SELECTORS.dropdown);
                
    // close active dropdown
    hideNavDropdown(false);

    // open current dropdown
    $dropdownWrapper.addClass('show');
    $('header').addClass('show-on-top');
    $dropdownWrapper.find(SELECTORS.dropdownMenu).addClass('show').removeClass('d-none');

    // add overlay
    if (!$(SELECTORS.menuOverlay).length) {
        $(SELECTORS.header).append('<div class="menu-overlay"></div>');
    }
}

/**
 * Manages the desktop navigation menu.
 * @param {Event} element - The event object representing the element that triggered the function.
 */
function manageDesktopNav(element) {
    const currentTarget = element.target;
    let updatedTarget = currentTarget;

    // Track the cursor position
    $(document).on('mouseenter', 'body *', (e) => updatedTarget = e.target);

    // Adding a delay to make sure the cursor is still on toggler and show the dropdown menu
    setTimeout(() => {
        if (currentTarget === updatedTarget) {
            showDesktopDropdownMenu(currentTarget);
        }
    }, 500);
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    $('.toggle-nav').on('keydown', function (e) {
        e.stopPropagation();
    })

    $('.toggle-nav').on('keyup', function (e) {
        if (e.key == 'Enter' || e.key == ' ') {
            if ($(this).closest('.dropdown').hasClass('nav-item')) { // top level
                $('header').addClass('show-on-top');
                $('.navbar-nav .show').removeClass('show')
                    .children('.dropdown-menu')
                    .removeClass('show');
                $(this).closest('.dropdown').addClass('show').children('.dropdown-menu')
                    .attr('aria-hidden', 'false')
                    .removeClass('d-none')
                    .addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(this).closest('.dropdown').find('.dropdown-list').attr('aria-hidden', false);
                $(this).closest('.dropdown').find('.dropdown-container').find('a').first().focus();
                    if (!$('.menu-overlay').length) {
                        $('header').append('<div class="menu-overlay"></div>');
                    }
            }
        }
    })
    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .navbar .nav-link, .navbar .dropdown-link, .toggle-nav',
        {
            40: function (menuItem) { // down
                var isBurgerMenu = $(menuItem).closest('.multilevel-dropdown').length > 0;
                if(isBurgerMenu) {                    
                    if (menuItem.hasClass('nav-item')) { // top level
                        $('.navbar-nav .show').removeClass('show')
                            .children('.dropdown-menu')
                            .removeClass('show');
                        $('header').removeClass('show-on-top');
                        menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                        if($(this).parent().children('.dropdown-menu').find('.back').length === 0 && $(this).closest('.navigation-wrapper').length === 0) {
                            var closeMenu = $('<div class="nav-menu"></div>');
                            closeMenu.append($('.back').first().clone());
                            $(this).parent().children('.dropdown-menu')
                                .prepend(closeMenu)
                                .attr('aria-hidden', 'false')
                                .removeClass('d-none');
                        } else {
                            $(this).parent().children('.dropdown-menu').attr('aria-hidden', 'false').removeClass('d-none');
                        }
                        
                        menuItem.find('.dropdown-list').attr('aria-hidden', false);
                        menuItem.find('ul > li > a')
                            .first()
                            .focus();
                    } else {
                        menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                        if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                            menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                        } else {
                            menuItem.next().children().first().focus();
                        }
                    }
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        if(($(menuItem).children('.sr-only').length > 0) && !$(menuItem).find('.toggle-nav').is(':focus')) {
                            $(menuItem).find('.toggle-nav').focus();
                        } else if (menuItem.hasClass('dropdown-item'))  {
                            menuItem.next().children().first().focus();
                        } else {
                            menuItem.closest('.nav-item').next().children().first().focus();
                        }
                    }
                }
            },
            39: function (menuItem) { // right
                var isBurgerMenu = $(menuItem).closest('.multilevel-dropdown').length > 0;
                if(isBurgerMenu) {   
                    if (menuItem.hasClass('nav-item')) { // top level
                        menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                        $(this).attr('aria-expanded', 'false');
                        menuItem.next().children().first().focus();
                    } else if (menuItem.hasClass('dropdown')) {
                        menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                        $(this).attr('aria-expanded', 'true');
                        menuItem.find('ul > li > a')
                            .first()
                            .focus();
                    }
                } else {
                    if(($(menuItem).children('.sr-only').length > 0) && !$(menuItem).find('.toggle-nav').is(':focus')) {
                        $(menuItem).find('.toggle-nav').focus();
                    } else if (menuItem.hasClass('dropdown-item'))  {
                        menuItem.next().children().first().focus();
                    } else {
                        menuItem.closest('.nav-item').next().children().first().focus();
                    }
                }
            },
            38: function (menuItem) { // up
                var isBurgerMenu = $(menuItem).closest('.multilevel-dropdown').length > 0;
                if(isBurgerMenu) {  
                    if (menuItem.hasClass('nav-item')) { // top level
                        menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    } else if (menuItem.prev().length === 0) { // first menuItem
                        menuItem.parent().parent().removeClass('show')
                            .children('.nav-link')
                            .attr('aria-expanded', 'false');
                        menuItem.parent().children().last().children() // set the focus to the last menuItem
                            .first()
                            .focus();
                    }
                } else {
                    if (menuItem.prev().length === 0) {
                        menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                    } else if(($(menuItem).prev().children('.sr-only').length > 0) && !$(menuItem).find('.toggle-nav').is(':focus')) {
                        $(menuItem).prev().find('.toggle-nav').focus();
                    } else if ($(menuItem).find('.toggle-nav').is(':focus')) {
                        menuItem.closest('.nav-item').children().first().focus();
                    } else {
                        menuItem.prev().children().first().focus();
                    }
                }
            },
            37: function (menuItem) { // left
                var isBurgerMenu = $(menuItem).closest('.multilevel-dropdown').length > 0;
                if(isBurgerMenu) {   
                    if (menuItem.hasClass('nav-item')) { // top level
                        $('.nav-menu').detach();
                        $(menuItem).find('.dropdown-menu').removeClass('show');
                        $(this).attr('aria-expanded', 'false');
                        menuItem.prev().children().first().focus();
                    } else {
                        $('.nav-menu').detach();
                        menuItem.closest('.show').removeClass('show').addClass('d-none')
                            .closest('li.show').removeClass('show')
                            .children()
                            .first()
                            .focus()
                            .attr('aria-expanded', 'false');
                    }
                } else {
                    if(($(menuItem).prev().children('.sr-only').length > 0) && !$(menuItem).find('.toggle-nav').is(':focus')) {
                        $(menuItem).prev().find('.toggle-nav').focus();
                    } else if ($(menuItem).find('.toggle-nav').is(':focus')) {
                        menuItem.closest('.nav-item').children().first().focus();
                    } else {
                        menuItem.prev().children().first().focus();
                    }
                }
  
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                clearSelection(parentMenu);
                parentMenu.children('.show').removeClass('show');
                menuItem.closest('.nav-item').find('.dropdown-menu').addClass('d-none');
                menuItem.closest('.nav-item').find('.toggle-nav').attr('aria-expanded', 'false');
                parentMenu.find('.dropdown-list').attr('aria-hidden', true);
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                $('.nav-menu').detach();

                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $(document).on('keydown', function (e) {
        if (e.keyCode == escapeKey || e.code === 'Escape') {
            if ($('.dropdown-menu.show').length > 0) {
                $('.dropdown-menu.show').removeClass('show');
                $('header').addClass('show-on-top');
            }
        }
    });

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', function (e) {
        var menuView = $('.navigation-wrapper').hasClass('long-menu') ? 900 : 833;
        if (!isDesktop(this) && $(window).width() < 600) {
            $(this).closest('.navbar-nav').find('.nav-link').attr('tabindex', '-1');
            $(this).closest('.navbar').find('.hamburger-menu-bottom').addClass('d-none');
            $(this).closest('.navbar').find('.locale-selector').addClass('d-none');
            $(this).closest('.navbar').find('.logo-home-mobile').addClass('d-none');
            $(this).closest('.navbar').find('.back').removeClass('d-none');
            $('.background').addClass('d-block');
            $(this).parent().addClass('show');
            $('header').addClass('show-on-top');
            $(this).parent().find('.dropdown-menu').removeClass('d-none');
            $(this).attr('aria-expanded', 'true');
            $('ul.dropdown-list').attr('aria-hidden', 'false');
            $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            e.preventDefault();
        } else if (!isDesktop(this) && $(window).width() >= 600 && $(window).width() <= menuView ) {
            e.preventDefault();
            var thisCat = $(this).data('dropdown-toggle');
            $(".navigation-wrapper").find(`[data-dropdown-target='${thisCat}']`).removeClass('d-none').addClass('show-tablet');
        }
    });

    if (isDesktop('.navigation-wrapper')) {
        $(document).on('mouseenter', '.dropdown:not(.disabled) [data-toggle="dropdown"]', (e) => manageDesktopNav(e));
        $(document).on('mouseenter', '.nav-item:not(.dropdown)', () => hideNavDropdown(true));
        $(document).on('mouseleave', '.menu-group', () => hideNavDropdown(true));
    }    

    $(document).on('click touch', '.back-tablet', function () {
        $('.menu-group .dropdown-menu').removeClass('show-tablet');
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        clearSelection($('.back'));
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('header').removeClass('show-on-top');
        $('.menu-toggleable-left').removeClass('in').addClass('d-none');
        $('.logo-home-mobile').removeClass('d-none');
        $('.navbar').find('.back').addClass('d-none');

        $('.main-menu').addClass('d-none');
        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').attr('aria-modal', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
        $('.buger-menu-button').attr('aria-expanded', 'false');
        $('.background').removeClass('d-block');
        $(".navigation-wrapper").find('.dropdown-menu').removeClass('show-tablet');
    });

    $('.navbar').on('click', '.back', function (e) {
        e.preventDefault();
        $(this).addClass('d-none');
        $(this).closest('.navbar').find('.logo-home-mobile').removeClass('d-none');
        $(this).closest('.nav-item.dropdown').find('a.nav-link').focus();
        clearSelection(this);
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();

        if ($('.main-menu').hasClass('in')) {
            $('.main-menu').removeClass('in').addClass('d-none');
            $('.background').removeClass('d-block');

            $('.buger-menu-button').attr('aria-expanded', 'false');
            $('.main-menu').attr('aria-hidden', 'true');
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
            $('header').css('z-index', '1004'); // when closed, needs to stay behind sticky atc bar
        } else {
            $('.main-menu').removeClass('d-none').addClass('in');
            $('.background').addClass('d-block');
            
            $('.buger-menu-button').attr('aria-expanded', 'true');
            $('.main-menu').attr('aria-hidden', 'false');
            $('.main-menu').siblings().attr('aria-hidden', 'true');
            $('header').siblings().attr('aria-hidden', 'true');
            $('header').css('z-index', '1050'); // when opened, needs to stay on top of all components
        }

    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.menu-overlay').remove();
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });

    $('body').on('keydown', '#sg-navbar-collapse', function (e) {
        var lastElement = '.locale-selector';
        var productLinkExists = $(this).find('.dropdown.show').find('.content-wrapper').not('.d-none').find('.product-link').length > 0;
        if($('.locale-selector').hasClass('d-none') && productLinkExists) {
            lastElement = '.product-link'
        } 
        if($('.locale-selector').hasClass('d-none') && !productLinkExists) {
            lastElement = '.see-all-button'
        }
        var focusParams = {
            event: e,
            containerSelector: '#sg-navbar-collapse',
            firstElementSelector: '.close-button button',
            lastElementSelector: lastElement,
            nextToLastElementSelector: '.close-button button'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    var focusElement =  $('.refer-container .refer-button');

    $(document).on('click', '[id$="_frndby_close_btn"] img, [id$="_frndby_close_btn"] a', function (e) {
        focusElement.focus();
    });

    $(document).on('keydown', '[id$="_frndby_close_btn"] img, [id$="_frndby_close_btn"] a', function (e) {
        e.preventDefault();
        if (e.code === 'Enter' || e.keyCode === enterKey || e.code === 'Space' || e.keyCode === spaceKey) {
            $('[id$="_frndby_close_btn"] img').trigger('click');
            setTimeout(function() {
                focusElement.focus();
            }, 300);
        }
    });

    $('.burger-adv-container').each(function() {
        var urlLink = $(this).data('url');
    
        if (urlLink) {
            $(this).click(function() {
                window.location.href = urlLink;
            });
        } else {
            $(this).mouseenter(function() {
                $(this).css('cursor', 'default');
            });
        }
    });
};

    adjustDropdownHeight();

    $(window).on('resize', adjustDropdownHeight);

    $('.dropdown').on('touchend mouseenter', function(e) {
        adjustDropdownHeight();
    })

    function adjustDropdownHeight(e) {
        var viewportHeight = $(window).height() - $('header').height();
        var dropdowns = $('.dropdown-menu');
        var menuView = $('.navigation-wrapper').hasClass('long-menu') ? 900 : 833;

        dropdowns.each(function() {
          var dropdown = $(this);
          var dropdownTop = $('.nav-item').offset().top;
          var dropdownHeight = dropdown.outerHeight();

          if (dropdownTop < 0) {
            var newDropdownHeight = dropdownHeight + dropdownTop;
            $('.dropdown-menu').css({'height':''});
            dropdown.height(newDropdownHeight);
          } else if (dropdownHeight > viewportHeight) {
            $('.dropdown-menu').css({'height':''});
            dropdown.height(viewportHeight);
          } else {
            //
          }
        });

        if ($(window).width() < 600 || $(window).width() > menuView ) {
            $(".navigation-wrapper").find('.dropdown-menu').removeClass('show-tablet');
        }
    }
