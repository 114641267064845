'use strict';

const MAX_STARS = 5;
/**
 * Create star SVG
 * @param {number} x1 - X1
 * @param {number} y1 - Y1
 * @param {number} index - Index
 * @param {number} star - Star
 * @returns {string} - Star SVG
 */
function createStarSVG(x1, y1, index, star) {
    let svgSize = '21px';
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}" viewBox="0 0 25 25" focusable="false">
            <polygon points="" style="fill: url(&quot;#bv_rating_summary_star_filled_${index}_${star}&quot;) !important;"></polygon>
            <path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style="fill: url(&quot;#bv_rating_summary_star_filled_${index}_${star}&quot;) !important;"></path>
            <defs>
                <lineargradient id="bv_rating_summary_star_filled_${index}_${star}" x1="${x1}%" y1="${y1}%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color: rgb(224, 123, 113); stop-opacity: 1;"></stop>
                    <stop offset="1%" style="stop-color: rgb(230, 230, 230); stop-opacity: 1;"></stop>
                </lineargradient>
            </defs>
        </svg>
    `;
}

/**
 * Create star rating HTML
 * @param {number} averageRating - Average rating
 * @param {number} totalCount - Total number of reviews
 * @param {string} svgNoWrapClass - SVG no wrap class
 * @param {number} index - Index
 * @returns {string} - Star rating HTML
 */
function createStarRating(averageRating, totalCount, svgNoWrapClass, index) {
    let starRatingHTML = `<div class="bv_main_container"> <div class="bv_stars_component_container ${svgNoWrapClass || ''}">`;
    for (let star = 1; star <= MAX_STARS; star++) {
        let x1 = '0';
        let y1 = '0';
        let percentage = 0;
        if (star <= averageRating) {
            percentage = 100;
            x1 = '99.99';
            y1 = '0';
        } else if (star - 1 < averageRating && averageRating < star) {
            percentage = ((averageRating - (star - 1)) * 100).toFixed(2);
            x1 = percentage;
            y1 = '0';
        }

        starRatingHTML += createStarSVG(x1, y1, index, star);
    }
    starRatingHTML += '</div>';
    starRatingHTML += `<div class="bv_average_rating_container">${averageRating}</div>`;
    starRatingHTML += `<div class="bv_num_reviews_container">(${totalCount.toLocaleString()})</div>`;
    starRatingHTML += '</div>';
    return starRatingHTML;
}

function loadRatingReviewSummary() {
    $('.bv-custom-rating-review-summary').each(function (index, element) {
        // check if the element has already been loaded
        if ($(element).hasClass('loaded')) {
            return;
        }
        $(element).html(createStarRating($(element).data('bv-total-rating'), $(element).data('bv-total-reviews'), $(element).data('svg-no-wrap-class'), index));
        // add the class loaded to the element
        $(element).addClass('loaded');
    });
}

module.exports = function () {
    loadRatingReviewSummary();
    // add event to reload reviews when the page is updated
    $('body').on('product:loadRatingReviewSummary', loadRatingReviewSummary);
}
