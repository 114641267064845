'use strict';

var slickConfigs = require('../config/slickConfigs');

module.exports = {
    livePersonTextBlock: function () {
        $(document).on('click', '.live-person-chat', function () {
            window.lpTag.events.trigger('LPT', 'SHOW_CHAT')
        });
    },
    textAndIconBlockView: function () {
        var $carousel = $('.text-and-icon-block-layout-carousel .experience-components');
        var vw = window.innerWidth;
        $carousel.each(function () {
            if ($(this).length) {
                if (vw > 767) {
                    if ($(this).length && $(this).hasClass('slick-initialized')) {
                        $(this).slick('unslick');
                    }
                } else {
                    $(this).not('.slick-initialized').slick(slickConfigs.textAndIconBlockView);
                }
            }
        });

        $(document).on('click', '.text-and-icon-block-asset.clickable-item', function () {
            var url = $(this).find('.link').attr('href');
            window.location.href = url;
        });

        $(window).on('resize', function () {
            var vw = window.innerWidth;
            if (vw > 767) {
                if ($carousel.length && $carousel.hasClass('slick-initialized')) {
                    $carousel.slick('unslick');
                }
            } else {
                $carousel.each(function () {
                    $(this).not('.slick-initialized').slick(slickConfigs.textAndIconBlockView);
                });
            }
        });
    },
    MattrassesProps: function () {
        $('ul.nav-tabs').on('click', 'li button:not(.active)', function() {
            $(this).closest('.nav-tabs').find('li button').removeClass('active');
            var mobile = $(this).closest('.nav-tabs').prev();
            
            $(this).addClass('active')
            .closest('.nav-tabs').siblings('div.tab-pane').removeClass('active').eq($(this).index()).addClass('active');
    
            var tabIndex = $(this).data('bs-toggle').replace(/(.*)#tab-/, '');
            // change mobile tabs logic
            mobile.find('.tab-pane').removeClass('active show');
            mobile.find('#' + tabIndex + '-tab-mobile').addClass('active show');
            $(this).closest('.nav-tabs').siblings('.tab-content').find('div.tab-pane').removeClass('show active', function () {
                $('.w-css-reset-button-important').trigger('click');
            });
            $('.mobile-video-block .tab-pane').removeClass('show active', function () {
                $('.w-css-reset-button-important').trigger('click');
            });
            $('#' + tabIndex + '-mobile').addClass('show active', function () {
                $('.w-big-play-button').trigger('click');
            });
            $('#' + tabIndex).addClass('show active', function () {
                $('.w-big-play-button').trigger('click');
            });
        });
    },
    accordion: function () {
        $(document).on('click', '.accordion-heading', function (e) {
            e.preventDefault();
            $('.accordion-heading').find('button').attr('aria-expanded', false);
            if (!$(this).hasClass('accordion-active')) {
                $(this).closest('.accordions-info').find('.accordion-content').slideUp(300);
                $(this).closest('.accordions-info').find('.accordion-heading').removeClass('accordion-active');
                $(this).find('button').attr('aria-expanded', true);
            }
    
            $(this).toggleClass('accordion-active');
            $(this).next().slideToggle(300);
        });
    },

    // show mattress slider on Mattress page
    shortedMattress: function () {

        var sliderContainer = $('.mobile-slider-active .experience-commerce_layouts-mobileGrid3r1c .mobile-3r-1c .row.mx-n2, .mobile-slider-active .experience-commerce_layouts-mobileGrid2r1c .mobile-2r-1c .row.mx-n2, #homepage .enable-mattresses-carousel .mobile-3r-1c .row.mx-n2');

        function initSlider() {
            $('.experience-commerce_layouts-mobileGrid3r1c .mobile-3r-1c .row.mx-n2, .experience-commerce_layouts-mobileGrid2r1c .mobile-2r-1c .row.mx-n2, #homepage .enable-mattresses-carousel .mobile-3r-1c .row.mx-n2').on('init', function(){
                if ($(this).find('.slider-buttons').length === 0) {
                    $('<div>', {
                        class: 'slider-buttons'
                    }).appendTo($(this));

                    $($(this).find('.slick-arrow')).appendTo($(this).find('.slider-buttons'));
                }
            });
    
            if($(window).width() <= 767) {
                sliderContainer.not('.slick-initialized').slick(slickConfigs.shortedMattress);
                sliderContainer.slick('slickGoTo', 0);

            }
        }
        
        initSlider();

        $(window).on('resize', function () {
            initSlider();

            $(window).on('breakpoint', function(event, _slick, breakpoint) {               
                function debounce(func, wait, immediate) {
                    var timeout;
                    return function() {
                      var context = this, args = arguments;
                      var later = function() {
                        timeout = null;
                        if (!immediate) func.apply(context, args);
                      };
                      var callNow = immediate && !timeout;
                      clearTimeout(timeout);
                      timeout = setTimeout(later, wait);
                      if (callNow) func.apply(context, args);
                    };
                  };
                
                    var unslickify = debounce(function() {
                        $('.mattresses-grid.slick-initialized').slick('unfilterSlides');
                        $('.mattresses-grid.slick-initialized').slick('unslick');
                    }, 250);

                    if($(window).width() > 767) {
                        unslickify();
                    }
            });
        }) 
    },
    launchEmbedChat: function () {
        $(document).on('click', '.chat-now', function (e) {
            if (embeddedservice_bootstrap && embeddedservice_bootstrap.utilAPI) {
                embeddedservice_bootstrap.utilAPI.launchChat();

                var proavtiveChat = document.querySelector('#proactive-chat');
                if (proavtiveChat) {
                    proavtiveChat.style.display = 'none';
                }
            }
        });
    }
}