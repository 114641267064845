'use strict';

module.exports = {
    init: function(){
        if ($('#friendbuyModal').length > 0) {
            var cookieExists = window.document.cookie.indexOf('frb_modal=1') > -1;
                if (!cookieExists) {
                    $('#friendbuyModal').modal('show');
                    window.document.cookie = 'frb_modal=1; Path=/;';
                }
        }
    }
}