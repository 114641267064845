'use strict';

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingOrderObject) {
    var html = '';
    $('.approaching-discounts-message').empty();
    if (approachingOrderObject && approachingOrderObject.approachingMessage) {
        html += approachingOrderObject.approachingMessage;
    }

    if (approachingOrderObject && approachingOrderObject.titleMsg) {
       $('.progress-bar-title').html(approachingOrderObject.titleMsg)
    }

    $('.approaching-discounts-message').append(html);

    $('.approaching-saved-discount-message').empty();
    html = '';
    if (approachingOrderObject && approachingOrderObject.saveMessage) {
        html += approachingOrderObject.saveMessage;
    }
    $('.approaching-saved-discount-message').append(html);

    if (approachingOrderObject.successStatus) {
        $('.approaching-discounts').addClass('success');
    }

    if (!approachingOrderObject.successStatus && $('.approaching-discounts').hasClass('success')) {
        $('.approaching-discounts').removeClass('success');
    }

    if (approachingOrderObject && approachingOrderObject.goalProgressBar && approachingOrderObject.currentTotalProgressBar) {
        var num = (Number(approachingOrderObject.currentTotalProgressBar)/Number(approachingOrderObject.goalProgressBar)) * 100;
        var progressLevel = Math.round(num);
        $('.approaching-discounts').find('.progress-bar').attr('aria-valuenow', progressLevel).css('width', progressLevel+'%');
        $('.approaching-discounts').find('.progress-bar').removeClass('d-none');
    } else {
        $('.approaching-discounts').find('.progress-bar').addClass('d-none');
    }
}

/**
 * checks whether the quantity of the product does not exceed the allowable quota
 * @param {Number} quota - allowable quota
 * @param {Object} items- all items in the basket
 * @param {String} uuid - current uuid of product which is checking
 */
function checkQuotaOfProduct(quota, items, uuid) {
    if(items && uuid) {
        Object.keys(items).forEach(function(key) {
            if(items[key].UUID == uuid) {
                    var $qty = $('.quantity[data-uuid="' + uuid + '"]').closest('.qty').find('.plus');
                    if (items[key].quantity < quota) {
                        $qty.prop('disabled', false);
                    } else {
                        $qty.prop('disabled', true);
                    }
                return;
            }
        });
    }
}

module.exports = {
    updateApproachingDiscounts: updateApproachingDiscounts,
    checkQuotaOfProduct: checkQuotaOfProduct
}