'use strict';

/**
 * Creates navigation events for GTM on click
 */
function navigationClick(event, pageUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl
    });
}

/**
 * Creates navigation events value props for GTM on click
 */
function navigationValuePropClick(event, pageUrl, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "propBarText": value
    });
}

/**
 * Creates set locale event for GTM on click
 */
function setLocaleClick(event, pageUrl, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "locale_selected": value
    });
}

/**
 * Creates click footer social event for GTM on click
 */
function clickFooterSocial(event, pageUrl, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "socialIcon": value
    });
}

/**
 * Creates click footer link event for GTM on click
 */
function clickFooterLink(event, pageUrl, topLevel, value) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "footerText": topLevel,
        "subCategoryFooterText": value
    });
}

/**
 * Creates click apply coupon promo event for GTM on click
 */
function clickApplyCoupon(event, pageUrl, promoCode, success) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "promoCode": promoCode,
        "successful": success
    });
}

/**
 * Creates click FAQ event for GTM on click
 */
function clickFAQ(event, pageUrl, question) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "question_clicked": question
    });
}

/**
 * Creates click embed chat event for GTM on click
 */
function clickEmbedChat(event, pageUrl, button) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "pageUrl": pageUrl,
        "button": button
    });
}

/**
 * Creates navigation events of category level  for GTM on click
 */
function levelNavigationClick(event, pageUrl, content, menuType) {
    window.dataLayer = window.dataLayer || [];
    var obj = {
        event: event,
        pageUrl: pageUrl,
        headerText: content.level,
        menu_type: menuType
    };
    if (content.subCategory) {
        var subCategory = {
            "subCategoryHeaderText": content.subCategory,
            "badging": content.badging,
            "spotlight_click": content.spotlight
        }
        $.extend(obj, subCategory);
    }
    window.dataLayer.push(obj);
}

/**
 * Creates fileters and sorting event for GTM on click
 */
function filterOrSortingClick(event, pageUrl, content) {
    window.dataLayer = window.dataLayer || [];
    var obj = {
        event: event,
        pageUrl: pageUrl
    };
    if (content.filter) {
        var filter = {
            filter_used: content.filter
        }
        $.extend(obj, filter);
    }
    if (content.sorting) {
        var sorting = {
            sort_used: content.sorting
        }
        $.extend(obj, sorting);
    }
    window.dataLayer.push(obj);
}

/**
 * Creates fileters and sorting event for GTM on click
 */
function filterOrSortingClickPlusValues(event, pageUrl, content) {
    window.dataLayer = window.dataLayer || [];
    var obj = {
        event: event,
        pageUrl: pageUrl
    };

    if (content.collection) {
        var collection = {
            collection_option: content.collection
        }
        $.extend(obj, collection);
    }

    if (content.size) {
        var size = {
            size_option: content.size
        }
        $.extend(obj, size);
    }

    if (content.filter) {
        var filter = {
            filter_used: content.filter
        }
        $.extend(obj, filter);
    }
    if (content.sorting) {
        var sorting = {
            sort_used: content.sorting
        }
        $.extend(obj, sorting);
    }
    window.dataLayer.push(obj);
}

function methodApply(event, deliveryMethod) {
    window.dataLayer = window.dataLayer || [];
    var obj = {
        event: event,
        "delivery_method": deliveryMethod
    };
    window.dataLayer.push(obj);
}

/**
 * Creates fileters and sorting event for GTM on click
*/

function emailSubmitedOnsite(event, email, hashedEmail, leadGenType) {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        "event": event,
        "email_address": email,
        "user_eh": hashedEmail,
        "lead_gen_type": leadGenType,
    };

    window.dataLayer.push(obj);
}

/**
 * Error Tracking data layer event push
 */
function errorTracking(event, errorType, errorMessage) {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        "event": event,
        "error_type": errorType,
        "error_message": errorMessage
    };

    window.dataLayer.push(obj);
}

/**
 * Creates click CTA event for GTM on clicking call to action buttons
 */
function clickCTA(event, url, component, cta_text, cta_link) {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': event,
        'pageUrl': url,
        'component': component,
        'cta_text': cta_text,
        'cta_link': cta_link,
    };
    window.dataLayer.push(obj);
}

/** 
 * Creates mattress event for GTM for mattress and size selections
*/
function mattressCompareSelection(compareProductValues) {
    window.dataLayer = window.dataLayer || [];

    if (!compareProductValues) return;

    var obj = {
        'event': 'mattress_compare_selections',
        ...compareProductValues
    };
    
    window.dataLayer.push(obj);
}

function cartSaveForLaterModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'CART - SAVE FOR LATER'
    };

    window.dataLayer.push(obj);
}

function cartProtectionPlanModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'CART - PROTECTION PLAN'
    };

    window.dataLayer.push(obj);
}

function referAFriendModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'HEADER - FRIENDBUY'
    };

    window.dataLayer.push(obj);
}

// affirm-site-modal
function homeAffirmModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'HOMEPAGE - AFFIRM'
    };

    window.dataLayer.push(obj);
}

// affirm-modal-trigger
function cartAffirmModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'CART - AFFIRM'
    };

    window.dataLayer.push(obj);
}

// pdp free delivery
function freeDeliveryModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'PDP - FREE DELIVERY'
    };

    window.dataLayer.push(obj);
}

// pdp free trial
function freeTrialModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'PDP - FREE TRIAL'
    };

    window.dataLayer.push(obj);
}

// pdp customer service
function customerServiceModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'PDP - CUSTOMER SERVICE'
    };

    window.dataLayer.push(obj);
}

// pillow friendbuy
function pillowFriendBuyModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'PILLOW - FRIENDBUY'
    };

    window.dataLayer.push(obj);
}

function smsCode20percentOffModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'EXIT INTENT - 20% OFF MODAL'
    };

    window.dataLayer.push(obj);
}

// quickview button
function quickViewModal() {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': 'modal_impression',
        'descriptor': 'QUICKVIEW BUTTON'
    };

    window.dataLayer.push(obj);
}

// quickview button
function quickViewSelect(event, descriptor, selectedValue) {
    window.dataLayer = window.dataLayer || [];

    var obj = {
        'event': event,
        'descriptor': descriptor,
        'selectedValue': selectedValue
    };

    window.dataLayer.push(obj);
}

// mattress quiz result
function mattressQuizResult(resultData, url) {
    const urlParams = new URLSearchParams(window.location.search);
    var obj = {
        url: url,
        event: 'mattress_quiz_result',
        name: resultData.name,
        id: resultData.id,
        variant_id: resultData.variant_id,
        variant: resultData.variant,
        price: resultData.price,
        shopping_for_size: urlParams.get('size'),
        position: urlParams.get('position'),
        dislike: urlParams.get('dislike'),
        features: urlParams.get('features'),
        invest: urlParams.get('invest'),
        who: urlParams.get('who'),
        shopping_journey: urlParams.get('shop')
    }
    window.dataLayer.push(obj);
}

/**
 * @param {Object} data The extend product data
 * @description add to cart data layer event.
 */
function extendAddToCartEvent(data) {
    if (data) {
        var obj = {
            event: 'ee_addToCart',
            name: 'Extend Protection Plan',
            id: data.id,
            sku: data.id,
            price:  Number.parseInt(data.price, 10) / 100,
            currency: 'USD',
            brand: 'EXTEND',
            product_type: 'Extend Protection Plan',
            quantity: Number.parseInt(data.qty, 10),
            variant: data.extendTerm,
            variant_id: data.id,
            parent_product_id: data.pid,
            utc_add_to_cart_at: new Date().toJSON().replace('T', ' ').split('.')[0],
            atc_location: data.atcLocation
        };
        window.dataLayer.push(obj);
    }
}

module.exports = {
    navigationClick: navigationClick,
    levelNavigationClick: levelNavigationClick,
    filterOrSortingClick: filterOrSortingClick,
    filterOrSortingClickPlusValues: filterOrSortingClickPlusValues,
    setLocaleClick: setLocaleClick,
    clickFooterSocial: clickFooterSocial,
    clickFooterLink: clickFooterLink,
    clickApplyCoupon: clickApplyCoupon,
    clickFAQ: clickFAQ,
    clickEmbedChat: clickEmbedChat,
    clickCTA: clickCTA,
    methodApply: methodApply,
    mattressCompareSelection: mattressCompareSelection,
    navigationValuePropClick: navigationValuePropClick,
    emailSubmitedOnsite: emailSubmitedOnsite,
    errorTracking: errorTracking,
    cartSaveForLaterModal: cartSaveForLaterModal,
    cartProtectionPlanModal: cartProtectionPlanModal,
    cartAffirmModal: cartAffirmModal,
    homeAffirmModal: homeAffirmModal,
    referAFriendModal: referAFriendModal,
    freeDeliveryModal: freeDeliveryModal,
    freeTrialModal: freeTrialModal,
    customerServiceModal: customerServiceModal,
    pillowFriendBuyModal: pillowFriendBuyModal,
    quickViewModal: quickViewModal,
    quickViewSelect: quickViewSelect,
    smsCode20percentOffModal: smsCode20percentOffModal,
    mattressQuizResult: mattressQuizResult,
    extendAddToCartEvent: extendAddToCartEvent
}
