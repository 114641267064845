'use strict';
/* global pageContext */

var dataLayer = window.dataLayer;
var DY = window.DY || {};
var dataLayerGTM =require('gtm/gtmDataLayer');

const currentPageAction = $('.page').data('action');
const Selector = {
    recommendationProduct: '.js-recommendation-product',
    cartRecommendationsWrapper: '.cart__recommendation',
    pdpRecommendationsWrapper: '.pdp__recommendation',
    upsellProduct: '.js-upsell-product',
    addToCartBtn: '.add-to-cart',
}

/**
 * @param {Object} productObject The product data
 * @description add to cart data layer event.
 */
function productAddToCart(productObject, quantity, atcLocation) {
    if (productObject) {
        var obj = {
            event: 'ee_addToCart',
            
            utc_add_to_cart_at: new Date().toJSON().replace('T', ' ').split('.')[0],

            ecommerce : {
                currency: productObject.currency,
                detail: {
                    products : [
                        {
                            name: productObject.name,
                            id: productObject.id,
                            sku: productObject.sku,
                            price: productObject.price,
                            discounted_price: productObject.discounted_price,
                            brand: productObject.brand,
                            product_type: productObject.product_type,
                            category: productObject.category,
                            quantity: Number.parseInt(quantity, 10),
                            variant: productObject.variant,
                            variant_id: productObject.variant_id,
                            g_product_id: productObject.g_product_id,
                            atcLocation: atcLocation
                        }
                    ]
                }
            }
        };
    
        dataLayer.push(obj);
    }
}

function addPDPBundle(event, url, productSetID) {
    if (event && url && productSetID) {
        var obj = {
            event: event,
            url: url,
            'product-set-id': productSetID 
        };

        dataLayer.push(obj);
    }
}


/**
 * @description SHA256 hashing
 * @param {string} email Email to be hashed
 */

async function encodeEmail(email) {
    const formattedEmail = email.trim().toLowerCase();
    const msgBuffer = new TextEncoder().encode(formattedEmail);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

/**
 * @param {Object} productObject The product data
 * @description remove from cart data layer event.
 */
 function removeFromCart(productObject, quantity) {
    if (productObject) {
        var obj = {
            event: 'ee_removeFromCart',
            name: productObject.name,
            id: productObject.id,
            sku: productObject.sku,
            price: productObject.price,
            discounted_price: productObject.discounted_price,
            currency: productObject.currency,
            brand: productObject.brand,
            product_type: productObject.product_type,
            category: productObject.category,
            quantity: Number.parseInt(quantity, 10),
            variant: productObject.variant,
            variant_id: productObject.variant_id,
            utc_remove_from_cart_at: new Date().toJSON().replace('T', ' ').split('.')[0],
            g_product_id: productObject.g_product_id,
        };
    
        dataLayer.push(obj);
    }
}

function productClick(productObject, position, clickLocation) {
    var obj = {
        event: "ee_productClick",
        category_name: productObject.category,
        category_id: productObject.category_id,
        product_name: productObject.name,
        product_type: productObject.product_type,
        sku: productObject.sku,
        product_id: productObject.id,
        product_price: productObject.price,
        currency: productObject.currency,
        product_brand: productObject.brand,
        product_position: position,
        g_product_id: productObject.g_product_id,
        variant: productObject.variant,
        variant_id: productObject.variant_id
    }

    if (clickLocation) {
        obj.click_location = clickLocation;
    }

    dataLayer.push(obj);
}

function heroPosterClick(heroData) {
    var obj = {
        event: heroData.event,
        pageUrl: heroData.pageUrl,
        eyebrow: heroData.eyebrow,
        header: heroData.header,
        sub_header: heroData.subHeader,
        cta: heroData.cta,
        disclaimer: heroData.disclaimer,
        position: heroData.position
    }

    dataLayer.push(obj);
}

function productDetails(productObject) {
    var obj = {
        event: "ee_productDetail",
        category: productObject.category,
        category_id: productObject.category_id,
        name: productObject.name,
        product_type: productObject.product_type,
        sku: productObject.sku,
        id: productObject.id,
        price: productObject.price,
        currency: productObject.currency,
        brand: productObject.brand,
        g_product_id: productObject.g_product_id,
        variant: productObject.variant,
        variant_id: productObject.variant_id
    }

    dataLayer.push(obj);
}

function validateEmail (email) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = pattern.test(email);

    return isValid;
}

/**
 * Updates the current step in the checkout flow and pushes event to data layer.
 * @param {Integer} step the step number the flow is currently on
 */
function updateCheckoutStep(step, eventData) {
    var eventName = '';
    switch (step) {
        case 1:
            eventName = 'ee_checkout_shipping_method';
            break;
        case 2:
            eventName = 'ee_checkout_shipping_method_option';
            break;
        case 3:
            eventName = 'ee_checkout_payment_method';
            break;
        case 4:
            eventName = 'ee_checkout_payment_method_option';
            break;
        case 5:
            eventName = 'ee_checkout_contact_information';
            break;
    }
    var obj = {
        event: eventName,
        ...eventData
    };
    dataLayer.push(obj);
}

/**
 * @description Convenience method for creating a click event.
 * @param {string} event event
 * @param {string} eventCategory event categroy
 * @param {string} eventAction event action
 * @param {string} eventLabel event layer
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

function setHashedEmailDataLayer(hashedEmail) {
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30); // expires in 30 days
    document.cookie = `user_eh=${hashedEmail}; expires=${expirationDate}; path=/`

    var dataLayer = window.dataLayer || [];
    var newShInfoEvent = {
        event: 'sh_info',
        user_eh: hashedEmail,
    };
    dataLayer.push(newShInfoEvent);
}

function getCompareProductValues() {
    const compareProductValues = {
        product_size: $('#mattress-compare-size') ? $('#mattress-compare-size').val() : null,
        product_columns: [],
        product_ids: [],
        product_names: [],
        product_prices: [],
    };

    $('.js-compare-card').each(function (index) {
        const gtmProductData = $(this).data('gtm-product');

        if (!$(this).is(':visible')) return;

        compareProductValues.product_columns.push(index + 1);
        gtmProductData && gtmProductData.id ? compareProductValues.product_ids.push(gtmProductData.id) : compareProductValues.product_ids.push('null');
        gtmProductData && gtmProductData.name ? compareProductValues.product_names.push(gtmProductData.name) : compareProductValues.product_names.push('null');
        gtmProductData && gtmProductData.price ? compareProductValues.product_prices.push(gtmProductData.price) : compareProductValues.product_prices.push('null');

    });

    return compareProductValues;
}

function iconNavigationClick(event, iconNavigationText, url) {
    dataLayer.push({
        event: event,
        navIconTitle: iconNavigationText,
        url: url,
    });
}

function mattressCardClick(eventName, url, productObject, mattressButtonText) {
    dataLayer.push({
        event: eventName,
        url: url,
        product_id: productObject.id,
        click_location: mattressButtonText,
    });
}

function expressCheckoutClick(paymentMethod) {
    dataLayer.push({
        event: 'express_checkout_click',
        payment_method: paymentMethod,
    });
}

var events = {
    account: function () {},
    cart: function () {},
    checkout: function () {},
    search: function () {},
    storefront: function () {},
    wishlist: function () {},
    // events that should happen on every page
    all: function () {
        // Remove from cart event trigger
        $(document).on('click', '.cart.cart-page .remove-product', function () {
            var gtmData = $(this).attr('data-gtmdata');
            var quantity = $(this).closest('.product-info').find('.quantity').data('currentquantity');
            removeFromCart($.parseJSON(gtmData), quantity);
        });
        // Add to cart event trigger
        $(document).on('click', '.add-to-cart, .add-to-cart-global', function () {
            var isQuickView = $(this).parents().closest('.product-quickview').length > 0;
            var isMattressQuiz = $(this).parents().closest('.background-container').length > 0;
            var upsellCheckbox = $('.upsell-checkbox-input');
            var atcDetail = isMattressQuiz ? 'Quiz Result - ATC' : 'PDP - ATC';
            var atcLocation = isQuickView ? 'Quickview - ATC' : atcDetail;
            productAddToCart($.parseJSON($(this).attr('data-gtmdata')), $(this).parents().find('.quantity-select').val(), atcLocation);

            upsellCheckbox.each(function() {
                if ($(this).prop('checked')) {
                    productAddToCart($.parseJSON($(this).attr('data-gtmdata')), $(this).data('item-quantity') || 1, 'Upsell Module');
                }
            });

            if ($('.pd-set-item').length > 0 && $('.pd-set-item-checkbox > input').is(':checked')) {
                var event = 'ee_bundleAdded';
                var url = window.location.href;
                var pdpProductSetID = $('.pd-set-item').attr('data-product-set-id');
                addPDPBundle(event, url, pdpProductSetID);

                $('.product-set-body-item').each(function(i, elem) {
                    var gtmData = JSON.parse($(elem).attr('data-gtmdata'));
                    var quantity = $(elem).attr('data-item-quantity');
                    productAddToCart(gtmData, quantity || 1, 'Bundle Upsell Module');
                });
            }
        });

        // Cart Recommendation
        $('body').on('click', '.cart .add-product-overlay-tile-js.cart-recommendation', function () {
            var atcLocation = 'Cart Recommendation';

            productAddToCart($.parseJSON($(this).attr('data-gtmdata')), 1, atcLocation);
        });

        // Post ATC upsell overlay
        $('body').on('click', '.add-product-overlay-tile-js:not(".added-product").pdp-overlay', function () {
            var atcLocation = 'Upsell Overlay';
            
            productAddToCart($.parseJSON($(this).attr('data-gtmdata')), 1, atcLocation);
        });

        // Proactive Chat
        $('body').on('gtm:proactiveChatTrigger', function (e) {
            dataLayer.push({
                event: 'proactive_chat_trigger',
                pageUrl: window.location.href
            });
        });

        $('.logo-home, .logo-home-mobile').on('click', function() {
            var url = window.location.href;
            var event ='click_header_logo';
            dataLayerGTM.navigationClick(event, url);
        });

        $(document).on('click','.hero-poster .outline-button, .hero-poster .primary-button, .hero-poster .secondary-button, .hero-poster .round-outline-button, .hero-poster .accent-button .rounded-primary-button', function() {
            var heroData = {
                event: 'click_homepage_cta'
            };
            heroData.pageUrl = $(this).attr('href');
            var cta = $(this).text();
            var disclaimer =  $(this).closest('.hero-poster').find('.footnote');
            var position = 0;
            try {
                var heroCarousel = $(this).closest('.slick-slide').data('slick-index');
                if (heroCarousel >= 0) {
                    position = heroCarousel == 0 ? 1 : 2;
                }
            } catch (e) {
                position = 0;
            }
            
            var eyebrowFull = $(this).closest('.main-content').find('.eyebrow');
            var headlineFull = $(this).closest('.main-content').find('.headline');
            var subHeaderFull = $(this).closest('.main-content').find('.list-edit');
            var addText = '';
            var eyebrow ='' ;
            $(eyebrowFull.find('>*')).each(function() {
                if (!$(this).hasClass('sr-only')) {
                    addText = $(this).text();
                    eyebrow =  eyebrow + ' ' + addText;
                }
                addText = '';
            });
            var headline = '';
            heroData.eyebrow = eyebrow.trim();
            $(headlineFull.find('>*')).each(function() {
                    addText = $(this).text();
                    headline =  headline + ' ' + addText;
                    addText = '';
            });
            heroData.header = headline.trim();
            var subHeader = '';
            $(subHeaderFull.find('>*')).each(function() {
                addText = $(this).text();
                subHeader =  subHeader + ' ' + addText;
                addText = '';
            });
            heroData.subHeader = subHeader.trim();
            heroData.cta = cta;
            heroData.disclaimer = disclaimer.find('p').text().replace('\n', '').trim();
            heroData.position = position;
            heroPosterClick(heroData);
        });

        $('.refer-container').on('click', '.refer-button', function() {
            var url = window.location.href;
            var event ='click_refer_a_friend';
            dataLayerGTM.navigationClick(event, url);
        });

        $('.refer-button').on('click', function() {
            var url = window.location.href;
            var event ='click_refer_a_friend';
            dataLayerGTM.navigationClick(event, url);
        });
    
        $(document).on('click', '.quickview', function() {
            var url = window.location.href;
            var event ='click_quickview';
            dataLayerGTM.navigationClick(event, url);

            // Product Details Event
            var productTileDiv = $(this).parents().closest('div[data-gtm-product]');
            if (productTileDiv.length > 0) {
                var productData = $.parseJSON(productTileDiv.attr('data-gtm-product'));
                productDetails(productData);
            }
        });
    
        $(document).on('click', '.phone-link', function() {
            var url = window.location.href;
            var event ='click_telephone_number';
            dataLayerGTM.navigationClick(event, url);
        });
    
        $(document).on('click', '.gtm-cta-button', function() {
            var url = window.location.href;
            var component = $(this).data('component');
            var cta_link = $(this).attr('href');
            var cta_text = $(this).text().replace(/(\r\n|\n|\r)/gm, '');
            var event ='click_cta';
            dataLayerGTM.clickCTA(event, url, component, cta_text, cta_link);
        });

        $(document).on('click', '.social-links a', function () {
            var social = $(this).attr('data-social');
            var url = window.location.href;
            var event = 'click_footer_social';
            dataLayerGTM.clickFooterSocial(event, url, social);
        });
    
        $(document).on('click', '.footer-list a', function() {
            var topLevel = $(this).parents('.column').find('.footer-title').text();
            var link = $(this).text().trim();
            var url = window.location.href;
            var event ='click_footer_link';
            dataLayerGTM.clickFooterLink(event, url, topLevel, link);
        });
    
        $('.minicart-link').on('click', function() {
            var url = window.location.href;
            var event ='click_cart';
            dataLayerGTM.navigationClick(event, url);
        });
    
        $('.panel-item').on('click', '.item-text',  function() {
            var url = window.location.href;
            var text = $(this).text();
            var event ='click_value_prop_bar';
            dataLayerGTM.navigationValuePropClick(event, url, text);
        });
    
        $('.store-container').on('click', '.stores', function() {
            var url = window.location.href;
            var event ='click_store_locater';
            dataLayerGTM.navigationClick(event, url);
        });

        $('.store-button').on('click', function() {
            var url = window.location.href;
            var event ='click_store_locater';
            dataLayerGTM.navigationClick(event, url);
        });
    
        $('.navbar-expand').on('click', '.nav-link, .list-link, .refer-button > .icon-text, .burger-adv-button', function() {
            var menuType = $(this).parents().closest('.navbar-toggleable').length ? 'side menu navigation' : 'top menu navigation';
            var url = window.location.href;
            var event ='click_navigation_header';
            var level =  $(this).contents();
            var content;
            var linkText = level[0]?.data?.replace(/\n/g, "") || level[0]?.innerText?.replace(/\n/g, "");
            if (linkText && linkText.trim() == '') {
                content = {level: $(this).children('span').text()}
            } else {
                content = {level: level[0]?.data?.trim() || level[0]?.innerText?.trim()}
            }
            dataLayerGTM.levelNavigationClick(event, url, content, menuType);
        });

        $('.dropdown-menu').on('click', '.dropdown-link, .see-all-button, .compare-button', function() {
            var menuType = $(this).parents().closest('.navbar-toggleable').length ? 'side menu navigation' : 'top menu navigation';
            var url = window.location.href;
            var event ='click_navigation_subcategory';
            var subCategory = $(this).find('span').text();
            var content =  $(this).contents();
            if (subCategory == '') {
                subCategory = content[0].data;
                subCategory = subCategory.replace(/\n/g, "");
            }
            var badging = $(this).find('.promo-message').text();
            var level =  $(this).closest('.dropdown-content').find('.categoty-name').data('category-value');
            var content = {
                level: level,
                subCategory: subCategory.trim(),
                badging: badging,
                spotlight: false
            }
            dataLayerGTM.levelNavigationClick(event, url, content, menuType);
        });
    
        $('.dropdown-content-image').on('click', '.product-link', function() {
            var menuType = $(this).parents().closest('.navbar-toggleable').length ? 'side menu navigation' : 'top menu navigation';
            var url = window.location.href;
            var event ='click_navigation_subcategory';
            var subCategory = $(this).find('img').attr("alt");
            var badging = $(this).find('.promo-message').text();
            var level =  $(this).closest('.dropdown-container').find('.categoty-name').data('category-value');
            var content = {
                level: level,
                subCategory: subCategory,
                badging: badging,
                spotlight: true
            }
            dataLayerGTM.levelNavigationClick(event, url, content, menuType);
        });

        // Apply Coupon Code
        $('body').on('gtm:applyCoupon', function(e, data) {
            var url = window.location.href;
            var event ='apply_promo';
            var success = !data.error;
            var couponCode = data.couponCode;
            dataLayerGTM.clickApplyCoupon(event, url, couponCode, success);
        });

        // Successful footer newsletter subscription 
        $('body').on('gtm:newsletter_subscription', async function() {
            var url = window.location.href;
            var event ='footer_newsletter_subscription_success';
            var email = $('input[id="email-guest"]').val();

            dataLayerGTM.navigationClick(event, url);
            if (!!email && validateEmail(email)) {
                var hashedEmail = await encodeEmail(email);
                var leadGenType = 'footer';
                var emailEvent = "lead_generation";

                setHashedEmailDataLayer(hashedEmail);

                dataLayerGTM.emailSubmitedOnsite(emailEvent, email, hashedEmail, leadGenType);
            }
        });

        $(document).on('click', '.mattress-quiz-email-submission',async function (e) {
            var email = $('input[id="mattress-quiz-email-guest"]').val();
            var event = 'lead_generation';

            if (!!email && validateEmail(email)) {
                var hashedEmail = await encodeEmail(email);
                var leadGenType = 'mattress_quiz';

                setHashedEmailDataLayer(hashedEmail);

                dataLayerGTM.emailSubmitedOnsite(event, email, hashedEmail, leadGenType);
            }
        });

        $(document).on('click', '.checkout-continue .checkout-btn', function(e) {
            const $this = $(this);
            try {
                if (typeof $this.attr('disabled') == 'undefined') {
                    e.preventDefault();
                    const targetUrl = $this.attr('href');
                    const url = $('.cart-page-container').attr('data-gtmdataurl');
    
                    if (!!url) {
                        $.ajax({
                            url: url,
                            method: 'GET',
                            dataType: 'json',
                            success: function (data) {
                                if (data.success) {
                                    data.gtmObject.page_path = window.location.pathname;
                                    dataLayer.push(data.gtmObject);
                                }
                                window.location = targetUrl;
                            },
                            error: function () {
                                window.location = targetUrl;
                            }
                        });
                    } else {
                        window.location = targetUrl;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });

        $(document).on('click', '.accordion-heading', function(){
            var question = $(this).children().text();
            var url = window.location.href;
            var event ='click_faq';
            dataLayerGTM.clickFAQ(event, url, question);
        });

        //Proactive Chat

        $('body').on('gtm:', function (e) {
            var data = {
                event: 'proactive_message_popup',
                pageUrl: window.location.href,
            } 

            dataLayer.push(data);

        });

        $(document).on('click', '.chat-cta', function () {
            var url = window.location.href;
            var event = 'proactive_chat_interaction';
            dataLayerGTM.clickEmbedChat(event, url, 'Chat Now');
        });

        $(document).on('click', '.chat-close-button', function () {
            var url = window.location.href;
            var event = 'proactive_chat_interaction';
            dataLayerGTM.clickEmbedChat(event, url, 'X');
        });
        $(document).on('click', '.chat-no-thanks', function () {
            var url = window.location.href;
            var event = 'proactive_chat_interaction';
            dataLayerGTM.clickEmbedChat(event, url, 'No Thanks');
        });

        // Cart - Protection Plan Modal
        $('.cart-page').on('click', '.learn-more', function() {
            var event ='modal_impression';
            var descriptor = 'CART - PROTECTION PLAN';
            dataLayerGTM.cartProtectionPlanModal(event, descriptor);
        });

        // Cart - Affirm Modal
        $('.cart-page').on('click', '.affirm-modal-trigger', function() {
            var event ='modal_impression';
            var descriptor = 'CART - AFFIRM';
            dataLayerGTM.cartAffirmModal(event, descriptor);
        });

        // Homepage - Affirm Modal
        $(document).on('click', '.affirm-site-modal', function() {
            var event ='modal_impression';
            var descriptor = 'HOMEPAGE - AFFIRM';
            dataLayerGTM.homeAffirmModal(event, descriptor);
        });

        // Global Header - Friendbuy icon/Refer a Friend
        $(document).on('click', '.refer-button', function() {
            var event ='modal_impression';
            var descriptor = 'HEADER - FRIENDBUY';
            dataLayerGTM.referAFriendModal(event, descriptor);            
        });

        // PDP - Free Delivery
        $(document).on('click', '#free-shipping-modal', function() {
            var event ='modal_impression';
            var descriptor = 'PDP - FREE DELIVERY';
            dataLayerGTM.freeDeliveryModal(event, descriptor);
        });

        // PDP - Free Trial
        $(document).on('click', '#free-trial-modal', function() {
            var event ='modal_impression';
            var descriptor = 'PDP - FREE TRIAL';
            dataLayerGTM.freeTrialModal(event, descriptor);
        });

        // PDP - Customer Service
        $(document).on('click', '#customer-service-modal', function() {
            var event ='modal_impression';
            var descriptor = 'PDP - CUSTOMER SERVICE';
            dataLayerGTM.customerServiceModal(event, descriptor);
        });

        // Pillow - Friendbuy
        $(document).on('click', '.friendbuy-plp-button', function() {
            var event ='modal_impression';
            var descriptor = 'PILLOW - FRIENDBUY';
            dataLayerGTM.pillowFriendBuyModal(event, descriptor);
        });

        function quickViewChangeSelect(event, descriptor, selectedValue) {
            var event = event;
            var descriptor = descriptor;
            var selectedValue = selectedValue;
            dataLayerGTM.quickViewSelect(event, descriptor, selectedValue);
        }

        // Quickview
        $(document).on('click', 'button[data-target="#quickViewModal"]', function() {
            var event = 'modal_impression';
            var descriptor = 'QUICKVIEW BUTTON';
            dataLayerGTM.quickViewModal(event, descriptor);

            // Quickview quantity change
            $('.quick-view-dialog').on('change', '#quantity-1', function() {
                var selectedValue = $(this).find(":selected").text().replace('\n', '').trim();
                quickViewChangeSelect('quickview_quantity_change','QUICKVIEW - QUANTITY CHANGE', selectedValue);
            });

            // Quickview size change
            $('.quick-view-dialog').on('change', '#size-1', function() {
                var selectedValue = $(this).find(":selected").text().replace('\n', '').trim();
                quickViewChangeSelect('quickview_size_change','QUICKVIEW - SIZE CHANGE', selectedValue);
            });

            $('.quick-view-dialog').on('change', '#quantity-1', function() {
                console.log('QUICKVIEW Change quantity dropdown', $(this).find(":selected").text().replace('\n', '').trim());
            });
        });

        // 20% OFF - SMS CODE
        $(document).on('click', 'a[data-click-trigger="1266386"]', function() {
            var event ='modal_impression';
            var descriptor = 'EXIT INTENT - 20% OFF MODAL';
            dataLayerGTM.smsCode20percentOffModal(event, descriptor);
        });

        //  Quantity change || add to cart event or remove from cart
        $(document).on('change', '.qty input', function(e) {
            const $this = $(this);
            const currentQuantity = +$this.attr('data-currentquantity');
            const targetQuantity = +e.target.value;
            const productData = $.parseJSON($this.attr('data-gtmdata'));

            if (currentQuantity < targetQuantity) {
                productAddToCart(productData, 1, 'Cart Increment');
            } else {
                removeFromCart(productData, 1);
            }

            $this.attr('data-currentquantity', targetQuantity);
            $this.attr('aria-label', 'quantity: ' + targetQuantity);
        });

        // Product click event trigger
        $(document).on('click', '.product-tile', function() {
            productClick($.parseJSON($(this).attr('data-gtm-product')), $.parseJSON($(this).attr('data-gtm-impression')).category_product_position);
        });

        // PDP Mini Upgrade || Compare Upsell Product click event trigger
        $(document).on('click', '.pdp__miniupgrade-modul, .pdp-compare .img-container', function() {
            productClick($.parseJSON($(this).attr('data-gtm-product')), 1, 'Mattress Card');
        });

        // On compare product change or initializes
        $(document).on('compareCore:compareProductsUpdate', () => dataLayerGTM.mattressCompareSelection(getCompareProductValues()));

        // === CHECKOUT EVENTS START ===
        // Submit Contact Information
        const checkoutGTMData = $('#checkout-main').data('gtmdata');
        $('body').on('checkout:gtmCustomerEdit', function () {
            updateCheckoutStep(5, checkoutGTMData);
        });

        $('body').on('checkout:gtmPaymentEdit', function () {
            updateCheckoutStep(3, checkoutGTMData);
        });

        $('body').on('checkout:gtmSubmitCustomer', async function () {
            updateCheckoutStep(1, checkoutGTMData);
            var email = $('input[id="email-guest"]').val();
            var isChecked = $('#add-to-email-list').prop('checked');

            if (!!email && isChecked && validateEmail(email)) {
                var hashedEmail = await encodeEmail(email);
                var leadGenType = 'checkout';
                var event = 'lead_generation';

                setHashedEmailDataLayer(hashedEmail);
    
                dataLayerGTM.emailSubmitedOnsite(event, email, hashedEmail, leadGenType);
            }
        });

        // Submit Shipping
        $('body').on('checkout:gtmSubmitShipping', function () {
            try {
                var selectedShippingMethod = $("input[id*='shippingMethod']:checked").attr('data-gtmoption').toLowerCase();

                updateCheckoutStep(2, { ...checkoutGTMData, option_value: selectedShippingMethod });

                if ($('.checkout__shipping-options').length) {
                    var shippingDate = $('.single-shipping .shipping-arrival').find('[id*="ship-date"]').map((i, elem) => $(elem).data('gtm') === 0 ? 'ASAP' : $(elem).data('gtm')).toArray();

                    updateCheckoutStep(2, {...checkoutGTMData, selected_shipment_date: shippingDate });

                }
                updateCheckoutStep(3, checkoutGTMData);
            } catch (error) {
                console.log(error);
            }
        });

        // Submit Payment
        $('body').on('checkout:gtmSubmitPayment', function () {
            try {
                var selectedPaymentMethod = $($('.tab-pane').filter((i, e) => $(e).hasClass('active'))[0]).attr('data-gtmname').toLowerCase();
                updateCheckoutStep(4, { ...checkoutGTMData, option_value: selectedPaymentMethod });
            } catch (error) {
            }
        });
        // === CHECKOUT EVENTS END ===

        $('.nav-link.dropdown-toggle').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.navbar-header.brand').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $('.minicart').on('mouseenter focusin touchstart', function () {
            $('.minicart .remove-product').on('click', function () {
                var gtmData = $(this).attr('data-gtmdata');
                $('.cart-delete-confirmation-btn').on('click', function () {
                    removeFromCart($.parseJSON(gtmData), 1);
                });
            });
        });

        $('body').on('gtmErrorTracking', function(e, data) {
            if (data && data.errorType && data.errorMessage) {
                const errorTypes = {
                    1: 'Checkout Error',
                    2: 'Lead Submission Error',
                    3: '404 Page'
                };

                const event = 'error_tracking';
                const errorType = errorTypes[data.errorType];
                dataLayerGTM.errorTracking(event, errorType, data.errorMessage);
            }
        });

        $(document).on('click', '.icon-navigation-item', function() {
            var event = 'nav_icon_click';
            var iconNavigationText = $(this).find('.icon-heading').text();
            var url = window.location.href;
            
            iconNavigationClick(event, iconNavigationText, url);
        });

        $(document).on('click', '.js-mattress-button', function() {
            var eventName = 'mattress_card_click';
            var mattressButtonText = $(this).data('gtm-text');
            var productObject = $(this).closest('.mattress-container').data('gtm-product');
            var url = window.location.href;

            mattressCardClick(eventName, url, productObject, mattressButtonText);
        });

        $(document).ready(function() {
            if ($('.page').length > 0 && $('.page').attr('data-action') === 'RedirectURL-Start') {
                $('body').trigger('gtmErrorTracking', {errorType: 3, errorMessage: $('.title p').text()});
            }

            $('#mattress-quiz-email-guest').on('input', function() {
                const errorField = $(this).siblings('span');
                const errorMessage = $(this).siblings('span').text();
    
                if (errorField.length > 0 && !!errorMessage) {
                    $('body').trigger('gtmErrorTracking', {errorType: 2, errorMessage: errorMessage});
                }
            });
        });

        $(document).on('click', '.js-express-payment', function() {
            expressCheckoutClick($(this).data('gtm-payment-type'));
        });

        $(document).on('click', '.js-pdp-in-store-link', function() {
            const event ='click_stores';
            const url = window.location.href;

            dataLayerGTM.navigationClick(event, url);
        });
    }
};

/**
 * @description prepare and push to data layer Product Impression event.
 */
function initProductImpressions() {
    if ($('.product-tile, .tailwind-product-tile').length > 0) {
        const pageCategoryData = $('.page').data('gtm-data');
        if (pageCategoryData) {
            
        const dataObject = {
            event: 'ee_productImpression',
            category_name: pageCategoryData.category_name,
            category_id: pageCategoryData.category_id,
            currency: pageCategoryData.currency,
            category_product_name: [],
            category_product_id: [],
            category_product_price: [],
            category_product_brand: [],
            category_product_type: [],
            category_product_sku: [],
            g_product_id: [],
            variant_id: [],
            category_product_variant: [],
            category_product_position: [],
            category_product_category: []
        };

        $('.product-tile, .tailwind-product-tile').each(function(i) {
            const $this = $(this);
            var gtmData = $this.data('gtm-impression');
            if (gtmData && gtmData.category_product_id && !dataObject.category_product_id.includes(gtmData.category_product_id)) {
                if (gtmData) {
                    Object.keys(dataObject).forEach(function(key) {
                        if (gtmData[key]) {
                            dataObject[key].push(gtmData[key]);
                        }
                    });
                    dataObject.category_product_position.push(i + 1);
                    gtmData.category_product_position = i + 1;
                    $this.attr('data-gtm-impression', JSON.stringify(gtmData));
                }
            }
            });

            dataLayer.push(dataObject);
        }
    }
};

function productImpressionsRecommendations(module) {
    const dataObject = {
        event: 'ee_productImpressionsRecommendations',
        recommendations_module: module,
        recommendations_url: window.location.href,
        recommendation_product_position: [],
        recommendation_product_id: [],
        recommendation_product_name: [],
        recommendation_product_price: [],
        recommendation_product_variant: [],
    };

    if (!$(Selector.recommendationProduct).length) {
        return;
    }

    $(Selector.recommendationProduct).each(function(i, el) {
        const $this = $(el);
        const gtmData = $this.data('gtm-impression');
        
        if (gtmData) {
            gtmData.category_product_id ? dataObject.recommendation_product_id.push(gtmData.category_product_id) : null;
            gtmData.category_product_name ? dataObject.recommendation_product_name.push(gtmData.category_product_name) : null;
            gtmData.category_product_price ? dataObject.recommendation_product_price.push(gtmData.category_product_price) : null;
            gtmData.category_product_variant ? dataObject.recommendation_product_variant.push(gtmData.category_product_variant) : null;
        }

        dataObject.recommendation_product_position.push(i + 1);
    });

    dataLayer.push(dataObject);
};

function productImpressionsUpsells() {
    const currentProductData = $(Selector.addToCartBtn).data('gtmdata');
    const dataObject = {
        event: 'ee_productImpressionsUpsells',
        primary_product_id: currentProductData ? currentProductData.id : null,
        primary_product_name: currentProductData ? currentProductData.name : null,
        upsell_product_position: [],
        upsell_product_id: [],
        upsell_product_name: [],
        upsell_product_variant: [],
        upsell_product_placement_type: []
    };

    if (!$(Selector.upsellProduct).length) {
        return;
    }

    $(Selector.upsellProduct).each(function(i, el) {
        const $this = $(el);
        const gtmData = $this.data('gtm-product') || $this.data('gtmdata');
        
        if (gtmData) {
            gtmData.id ? dataObject.upsell_product_id.push(gtmData.id) : null;
            gtmData.name ? dataObject.upsell_product_name.push(gtmData.name) : null;
            gtmData.variant ? dataObject.upsell_product_variant.push(gtmData.variant) : null;

            $this.data('gtm-placement-type') ? dataObject.upsell_product_placement_type.push($this.data('gtm-placement-type')) : null;

            dataObject.upsell_product_position.push(i + 1);
        }
    });

    dataLayer.push(dataObject);
};

function initMiniCompareImpression() {
    if ($('.js-mini-compare-current-product').length > 0 || $('.js-mini-compare-upgrade-product').length > 0) {
        const $currentProductElem = $('.js-mini-compare-current-product').length > 0 ? $('.js-mini-compare-current-product') : null;
        const $upgradeProductElem = $('.js-mini-compare-upgrade-product').length > 0 ? $('.js-mini-compare-upgrade-product') : null;

        $currentProductElem.each(function(i) {
            const currProductData = JSON.parse($($currentProductElem[i]).attr('data-gtm-product'));

            const dataObject = {
                event: 'mini_compare_impression',
                url: window.location.href,
                compare_product_position: [1],
                compare_product_id: [currProductData.id],
                compare_product_name: [currProductData.name],
                compare_product_price: [currProductData.price],
                compare_product_variant: [currProductData.variant]
            }

            if ($upgradeProductElem && $($upgradeProductElem[i]).length) {
                const upgradeProductData = JSON.parse($($upgradeProductElem[i]).attr('data-gtm-product'));

                dataObject.compare_product_position.push(2);
                dataObject.compare_product_id.push(upgradeProductData.id);
                dataObject.compare_product_name.push(upgradeProductData.name);
                dataObject.compare_product_price.push(upgradeProductData.price);
                dataObject.compare_product_variant.push(upgradeProductData.variant);
            }

            dataLayer.push(dataObject);
        });
    }
}

/**
 *
 * @description Mutation Observer to track changes in the DOM of '.cart__recommendation'
 * @param {string} wrapperSelector The selector of the recommendations wrapper
 * @param {function} callback The callback function to be executed when the DOM changes
 */
function trackRecommendationLoad(wrapperSelector, callback) {
    const recommendationsContainer = document.querySelector(wrapperSelector);
    // avoid running if the element is not present
    if (!recommendationsContainer) return;
    const observer = new MutationObserver(() => {
        callback();
    });

    observer.observe(recommendationsContainer, { childList: true, subtree: true });
}

/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    // Prepare Product Impressions
    $(window).on('load', () => {
        initProductImpressions();
        initMiniCompareImpression();
        productImpressionsUpsells();
    });

    $('body').on('postAtcOverlay:opened' , function () {
        trackRecommendationLoad(Selector.pdpRecommendationsWrapper, () => {
            if (!window.productImpressionsCalled) {
                productImpressionsRecommendations('post-ATC overlay');
                window.productImpressionsCalled = true;
            }
        });
    });

    if (currentPageAction === 'Cart-Show') {
        trackRecommendationLoad(Selector.cartRecommendationsWrapper, () => {
            if (!window.productImpressionsCalled) {
                productImpressionsRecommendations('cart');
                window.productImpressionsCalled = true;
            }
        });
    }

    var ns = nameSpace || pageContext.ns;
    if (ns && events[ns]) {
        events[ns]();
    }
    events.all();
};
