'use strict';

/**
 * Update Images in Tile after swatch click
 */
function updateTileImage() {
    $('body').on('click', '.tile-swatch', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        var url = $this.data('swatch-url');
        var $imageWrapper = $this.closest('.product-tile');
        var $image = $imageWrapper.find('.tile-image');
        var $link = $imageWrapper.find('.link-pdp');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $imageWrapper.find('.tile-swatch').removeClass('selected').attr('aria-pressed','false');
                $this.addClass('selected').attr('aria-pressed','true');
                var imgObject = data.images['pdptile'] ? data.images['pdptile'] : data.images['pdptile'] ? data.images['pdptile'] : data.images.medium;
                $image.attr({'src': imgObject[0].url, 'alt': imgObject[0].alt, 'title': imgObject[0].title});
                $link.attr('href', data.productUrl);
                var quickviewLink = '';
                try {
                    if($imageWrapper.hasClass('quickview')) {
                        quickviewLink = $imageWrapper.attr('data-href').split('=').slice(0, 1).join();
                    } else if(!$imageWrapper.hasClass('bundle')){
                        quickviewLink = $imageWrapper.find('.image-container .quickview').attr('data-href').split('=').slice(0, 1).join();
                    }
                } catch (e) {
                    quickviewLink = '';
                }

                var variationProduct = $this.closest('.tile-swatch').attr('data-href').split('_').slice(-2).join('&');
                var newQuickviewLink = quickviewLink + '=' + variationProduct;
                $imageWrapper.find('.image-container .quickview').attr('data-href', newQuickviewLink);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.tailwind-tile-swatch', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        var url = $this.data('swatch-url');
        var $imageWrapper = $this.closest('.tailwind-product-tile');
        var $image = $imageWrapper.find('.tailwind-tile-image');
        var $link = $imageWrapper.find('.tailwind-link-pdp');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $imageWrapper.find('.tailwind-tile-swatch[class*="tw-outline"').removeClass('tw-outline').attr('aria-pressed','false');
                $this.addClass('tw-outline').attr('aria-pressed','true');
                var imgObject = data.images['pdptile'] ? data.images['pdptile'] : data.images['pdptile'] ? data.images['pdptile'] : data.images.medium;
                $image.attr({'src': imgObject[0].url, 'alt': imgObject[0].alt, 'title': imgObject[0].title});
                $link.attr('href', data.productUrl);

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    updateTileImage: updateTileImage
};
