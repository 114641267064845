'use strict';

module.exports = {
    toggleButton: function () {
        $(document).on('click', '.tw-toggle-button', function () {
            const toggleId = $(this).data('toggle-id');
            const toggleComponent = $(`#${toggleId}`);

            if (toggleComponent) {
                toggleComponent.toggleClass('max-h-screen');
                toggleComponent.toggleClass('max-h-0');
                $(this).toggleClass('opacity-50');
            }
        });
    },
    anchorLinkClick: function () {
        const navContainer = $('.anchor-links-container');

        if (navContainer && navContainer.length > 0) {
            /* 
                if anchor links exists,
                1. sticky add to cart container needs to be at 0 top
                2. anchor links needs to be right below the sticky add to cart container
                3. on scroll up, they both need to go away
                    a. header needs to come back
                4. on scroll down, they both need to come back
                    a. header needs to go away

                option 
                    - keep the header behind the scroll down group (sticky add to cart container and anchor links)
                game plan 
                    - if anchor links are present, add classes to the header, sticky add to cart container, and the classes
                    will determine the behavior of the header and sticky add to cart container

            */
        

            const pdpMinicompare = $('.pdp-compare .compare');            
            if (pdpMinicompare && pdpMinicompare.length > 0) {
                pdpMinicompare.addClass('with-anchor-links');
            }

            const buffer = 100; // Buffer (how much space to leave between top of the page and the section)
            const links = $('.sticky-anchor-link');

            // Handle fixed positioning of the nav container
            $(window).on('scroll', function () {

                // Determine which section is currently in view
                const windowHeight = $(window).height();
                const scrollTop = $(window).scrollTop();
                let activeLinkFound = false;

                links.each(function () {
                    const sectionId = $(this).attr('href');
                    const section = $(sectionId);
                    if (section.length) {
                        // Calculates the section top and bottom based on the window height
                        const sectionTop = section.offset().top - buffer;
                        const sectionBottom = section.outerHeight() < windowHeight ? sectionTop + windowHeight :
                        sectionTop + section.outerHeight();
                        
                        // If the section is in view, highlight the corresponding anchor link
                        if (scrollTop + windowHeight / 2 >= sectionTop && scrollTop + windowHeight / 2 < sectionBottom) {
                            links.removeClass('tw-bg-blue-700 !tw-text-white')
                                .addClass('hover:tw-font-semibold !tw-text-blue-700');
                            $(this).addClass('tw-bg-blue-700 !tw-text-white')
                                .removeClass('hover:tw-font-semibold !tw-text-blue-700');
                            activeLinkFound = true;
                        }
                    }
                });

                if (!activeLinkFound) {
                    links.removeClass('tw-bg-blue-700 !tw-text-white')
                        .addClass('hover:tw-font-semibold !tw-text-blue-700');
                }
            });

            // Handle anchor link click for smooth scrolling with buffer
            $(document).on('click', '.sticky-anchor-link', function (event) {
                event.preventDefault();
                const targetId = $(this).attr('href');
                const targetElement = $(targetId);

                if (targetElement.length > 0) {
                    const targetPosition = targetElement.offset().top - buffer;

                    $('html, body').animate({
                        scrollTop: targetPosition
                    }, 500);
                }
            });

            // Recalculate section positions on resize
            $(window).on('resize', function () {
                const scrollPosition = $(window).scrollTop();
                const windowHeight = $(window).height();

                links.each(function () {
                    const sectionId = $(this).attr('href');
                    const section = $(sectionId);
                    if (section.length) {
                        const sectionTop = section.offset().top - buffer;
                        const sectionBottom = section.outerHeight() < windowHeight ? sectionTop + windowHeight : sectionTop + section.outerHeight();

                        if (scrollPosition + windowHeight / 2 >= sectionTop && scrollPosition + windowHeight / 2 < sectionBottom) {
                            links.removeClass('tw-bg-blue-700 !tw-text-white')
                                .addClass('hover:tw-font-semibold !tw-text-blue-700');
                            $(this).addClass('tw-bg-blue-700 !tw-text-white')
                                .removeClass('hover:tw-font-semibold !tw-text-blue-700');
                        }
                    }
                });
            });

            /*
                Dev Note:
                
                Custom code was used because:
                - Observer doesn't work dynamically with section height and window height. E.g if the section height is less than the window height, the observer will not work as expected.
                - Observer also doesnt work with components that are too close to each other. E.g. if the section is too close to the top of the page, the observer will not work as expected.
                - Observer will not work as expected deactiviating the anchor link when the section is out of view or when priorizing multiple sections in view.
            */
        }
    }
};
