'use strict';
var slickConfigs = require('../config/slickConfigs');
var cartHelper = require('../cart/cartHelper');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    if (data.totals.totalShippingCostValue === 0) {
        if (!data.totals.isSetShipments && data.totals.calculatedAtCheckoutMsg !== '') {
            $('.shipping-cost').empty().append(data.totals.calculatedAtCheckoutMsg);
        } else {
            $('.shipping-cost').empty().append(data.resources.free);
        }
    } else {
        $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    }

    $('.tax-total').empty().append(data.totals.totalTaxValue == 0 && !data.totals.isSetShipments ?  data.resources.calculatedAtCheckoutMsg : data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotalWithoutDiscounts);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    /* Affirm block for refreshing promo message */
    if (typeof affirm !== `undefined`) {
        var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, '');
        $('.affirm-as-low-as').attr('data-amount', (totalCalculated * 100).toFixed());
         // eslint-disable-next-line no-undef
        affirm.ui.refresh();
    }

    data.items.forEach(function (item) {
        if ($('.item-total-' + item.UUID).find('.line-item-discount').length > 0) {
            var $totalSavings = $('.item-total-' + item.UUID).find('.line-item-discount').clone();
        }
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        if (item.totalSavings) {
            if ($totalSavings) {
                var $totalSavingsSpan = $totalSavings.find('span').clone();
                $totalSavings.text(item.totalSavings + ' ').append($totalSavingsSpan);
                $('.item-total-' + item.UUID).append($totalSavings);
            } else {
                var $newTotalSavings = $(`<div class="line-item-discount">
                    ${item.totalSavings} <span class="off">OFF</span>
                </div>`);
                $('.item-total-' + item.UUID).append($newTotalSavings);
            }
        }
    });

    if (!!data.totalSavings) {
        if ($('.cart-total-savings').length > 0) {
            $('.cart-total-savings').find('.text-right').text(`-${data.totalSavings}`);
        } else {
            var $newCartTotalSavings = $(`<div class="row cart-total-savings">
                <div class="col-8"><p class="d-inline-block">Total Savings</p></div>
                <div class="col-4"><p class="text-right">-${data.totalSavings}</p></div>
            </div>`);
            $newCartTotalSavings.insertAfter('.totals .row:eq(0)');
        }
    } else {
        $('.cart-total-savings').remove();
    }

    if (data.totals.recycleFee) {
        if (data.resources.recycleFeeTooltipMsg) {
            $('.recycle-fee-tooltip').find('.popover-tooltip').html(data.resources.recycleFeeTooltipMsg);
            $('.recycle-fee-item').addClass('d-inline-block');
            $('.recycle-fee-item').removeClass('d-none');

        }
    } else {
        $('.recycle-fee-item').addClass('d-none');
        $('.recycle-fee-item').removeClass('d-inline-block');
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}


function resetButtonInfo(response, pid, $containerButtons) {
    if (response.cart && response.cart.items) {
    var items = response.cart.items;
    var $addButton = $containerButtons.find('.overlay-add-to-cart-url');
    //var $upsellButtons = $containerButtons.find('.overlay-remove-upsell-product');
    Object.keys(items).forEach(function(key) {
        if(items[key].id == pid) {
            $addButton.attr('data-pid', pid);
            $addButton.attr('data-uuid',items[key].UUID);
            return;
        }
    });
    }
}

function resetUpsellButtonInfo(response, $containerButtons) {
    if (response.cart && response.cart.items) {
        var items = response.cart.items;
        var containers = $containerButtons.find('.overlay-product-upsell');
        containers.each(function () {
            const $this = $(this);
            var pid = $(this).data('pid');
            var upsellInfo = $(this);
            for (var i = 0; i < items.length; i++) {
                if (items[i].id == pid) {
                    upsellInfo.attr('data-uuid',items[i].UUID);
                    break;
                }
            }
        });
    }
}

function updateQtyProduct (url, $wraperrButtonAdd, $buttonAdd) {
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            var numItems = data.basket ? data.basket.numItems : data.numItems;
            $('.minicart-quantity').empty().append(numItems);
            $wraperrButtonAdd.removeClass('added-product');
            $buttonAdd.removeClass('added-product');
            $buttonAdd.find('.button-text').html('Add');
        },
        error: function (err) {
            if (!err.responseJSON.productInCart) {
                $buttonAdd.removeClass('added-product');
                $wraperrButtonAdd.removeClass('added-product');
                $buttonAdd.find('.button-text').html('Add');
            }
        }
    });
}

function refreshCartWithAddedProduct ($page, form, itemUUID) {
    var cartShowProductUrl = $page.find('.cart-page-container').data('showproducts');
    cartShowProductUrl = appendToUrl(cartShowProductUrl, form);
    $.ajax({
        url: cartShowProductUrl,
        type: 'get',
        dataType: 'html',
        success: function (data) {
            var $grid = $page.find('.products-grid');
            var productOnPage = $grid.find('.product-info');
            var reloadProduct = false;
            var removeDiv;
            var uuidClass = 'uuid-' + itemUUID;
            productOnPage.each(function (){
                if ($(this).hasClass(uuidClass)){
                    reloadProduct = true;
                    removeDiv = $(this);
                }
            });
            if (reloadProduct) {
                removeDiv.remove();
            }
            $page.find('.products-grid').append($.parseHTML(data));
        },
        error: function (err) {
            console.log('cart reload:', err);
        }
    });
}

function refreshCartWithNewRecommendations ($page) {
    var recommendationsUrl = $page.find('.cart-page-container').data('showrecommendations');
    $.ajax({
        url: recommendationsUrl,
        type: 'get',
        dataType: 'html',
        success: function (data) {
            $page.find('.cart-recommendations-grid').html(data);

            let i = setInterval(function() {
                if ($('#recommendations-slider.slick-initialized').length > 0){
                    clearInterval(i);
                }
                $('.cart-page-container #recommendations-slider').not('.slick-initialized').slick(slickConfigs.recommendations);
                $('.cart__recommendation').removeClass('d-none');
            }, 200);
        },
        error: function (err) {
            console.log('cart recommendations reload:', err);
        }
    });
}


function getProductArr ($wraperrButtonAdd) {
    var removeInfo = $wraperrButtonAdd.find('.overlay-add-to-cart-url');
    var productID = removeInfo.attr('data-pid');
    var uuid = removeInfo.attr('data-uuid');
    var quantity = removeInfo.attr('data-itemqty');

    var containers = $wraperrButtonAdd.find('.overlay-product-upsell');
    var arrItemData = [];
    var url;
    if (containers.length > 0) {
        var upItem;
        var pid;
        var uuidUpsell;
        var quantityUpsell;
        containers.each(function () {
            pid = $(this).attr('data-pid');
            uuidUpsell = $(this).attr('data-uuid');
            quantityUpsell = $(this).attr('data-itemqty');
            if ( pid == productID && uuidUpsell == uuid) {
                quantity = parseInt(quantity) + parseInt(quantityUpsell);
            } else {
                upItem = {
                    pid: pid,
                    quantity: quantityUpsell,
                    uuid: uuidUpsell
                };
                arrItemData.push(upItem);
            }
        });
    }
    var urlParams = {
        pid: productID,
        quantity: quantity,
        uuid: uuid
    };

    arrItemData.push(urlParams);
    return arrItemData;
}

function updateSelectedAttrs ($this) {
    var $selectedProduct = $($this).closest('.add-product-overlay');
    var $selectedAttr = $selectedProduct.closest('.overlay-page-product').find('.recommendation-product-attribute')
    var attribites = []
    $selectedAttr.each(function () {
        attribites.push($(this).find('.attr-value').text());
    })

    if(attribites) {
        $selectedProduct.closest('.overlay-page-product').find('.selected-attr .selected-value').text(attribites.join(", "))
        $selectedProduct.closest('.overlay-page-product').find('.selected-attr').removeClass('d-none')
    }

}

function updateAddToCartFormDataOverlay( $addedProduct) {
    var containers = $addedProduct.find('.overlay-product-upsell');
    var upsellItemData = [];
    var upItem;
    var quantity;
    containers.each(function () {
        upItem = $(this).data('pid');
        quantity = $(this).data('item-quantity');
        upsellItemData.push({
            upsellID: upItem,
            quantity: quantity
        });
    });
    return JSON.stringify(upsellItemData);
}

module.exports = {
    initialize: function () {
        $('body').on('click', '.add-product-overlay-tile-js:not(".added-product")', function (e) {
            e.preventDefault();
            var $buttonAdd = $(this);
            if ($buttonAdd.hasClass('added-product')){
                return;
            }
            if ($('.cart-recommendations-grid').length) {
                $('.cart-recommendations-grid').css('height',$('.cart-recommendations-grid .cart__recommendation').height());
            }
            var $page = $(this).closest('.page');
            var isCartPage = $page.hasClass('cart-show-content');
            $buttonAdd.addClass('added-product');
            var $wraperrButtonAdd = $(this).closest('.add-product-overlay').find('.add-product-overlay');
            var $addedProduct = $(this).closest('.add-product-overlay').closest('.overlay-page-product');
            var addToCartUrl = $('.overlay-add-to-cart-url').val();
            var pid = $(this).closest('.add-product-overlay').find('.overlay-add-to-cart-url').data('pid');
            var $containerButtons = $(this).closest('.add-product-overlay');
            var form = {
                pid: pid,
                pidsObj: [],
                childProducts: [],
                quantity: '1'
            };

            // Handle Double Pack Default Variant Adding to Cart
            if (!!pid && pid.slice(-2) !== '-1') {
                form.upItems = updateAddToCartFormDataOverlay($addedProduct);
            }

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        miniCartReportingUrl(data.reportingURL);
                        if ( !data.error) {
                            updateCartTotals(data.cart);
                            if (isCartPage) {
                                var items = data.cart.items;
                                var itemUUID = '';
                                Object.keys(items).forEach(function(key) {
                                    if(items[key].id == pid) {
                                        itemUUID = items[key].UUID;
                                        return;
                                    }
                                });
                                refreshCartWithAddedProduct($page, form, itemUUID);
                                refreshCartWithNewRecommendations($page);
                                cartHelper.updateApproachingDiscounts(data.cart.approachingOrderObject);
                                $('body').trigger('extend:cart:update');
                            } else {
                                $wraperrButtonAdd.addClass('added-product');
                                $addedProduct.addClass('added');
                                $buttonAdd.find('.button-text').html('Added');
                                $('.modal-footer').find('.checkout-empty').addClass('d-none');
                                $('.modal-footer').find('.checkout-continue').removeClass('d-none');
                                resetButtonInfo(data, pid, $containerButtons);
                                updateSelectedAttrs($buttonAdd);
                                if (form.upItems && form.upItems.length > 0) {
                                    resetUpsellButtonInfo(data, $containerButtons);
                                }
                            }
                        }
                        if (window.DY) {
                            const cartItems = DY.recommendationContext ? DY.recommendationContext.data || [] : [];
                            cartItems.push(pid);
                            window.DY.recommendationContext = { type: "CART", data: cartItems };
                        }
                    },
                    error: function () {
                        $wraperrButtonAdd.removeClass('added-product');
                        $buttonAdd.removeClass('added-product');
                        $addedProduct.removeClass('added');
                        $('body').removeClass('pdp-veil');
                    }
                });
            }
        });

        $('body').on('click', '.add-product-overlay-tile-js.added-product', function (e) {
            e.preventDefault();
            var $buttonAdd = $(this);            
            var $addedProduct = $(this).closest('.add-product-overlay').closest('.overlay-page-product');

            if (!$buttonAdd.hasClass('added-product')){
                return;
            }

            $buttonAdd.removeClass('added-product');
            $addedProduct.removeClass('added');
            $('.modal-footer').find('.checkout-empty').removeClass('d-none');
            $('.modal-footer').find('.checkout-continue').addClass('d-none');
            var $wraperrButtonAdd = $(this).closest('.add-product-overlay');
            var removeInfo = $wraperrButtonAdd.find('.overlay-add-to-cart-url');
            var urlGetQty = removeInfo.data('getqty');
            var arrItemData = getProductArr($wraperrButtonAdd);
            var url;
            for (var i = 0; i < arrItemData.length; i++) {
                var arr = arrItemData[i];
                url = appendToUrl(urlGetQty, arr);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        var getCurrentQty = data.qtyAlreadyInCart;
                        var gty = arr.quantity;
                        if (getCurrentQty == gty) {
                            var urlRemove = removeInfo.data('actionremove');
                            url = appendToUrl(urlRemove, arr);
                            updateQtyProduct(url, $wraperrButtonAdd, $buttonAdd);
                        } else if (getCurrentQty > gty) {
                            var diffQty = getCurrentQty - gty;
                            arr.quantity = diffQty;
                            var urlUpdateQty = removeInfo.data('updateqty');
                            url = appendToUrl(urlUpdateQty, arr);
                            updateQtyProduct(url, $wraperrButtonAdd, $buttonAdd);
                        }
                    },
                    error: function (err) {
                        console.log('getQtyProduct', err);
                    }
                });
            }

        });

        $('body').on('click', '.upsell-overlay-item', function(e) {
            e.preventDefault();
            var url =  $(this).data('action');
            var productID = $(this).data('item');
            var $replaceProduct = $(this).closest('.overlay-page-product');
            var urlParams = {
                pid: productID
            };
            url = appendToUrl(url, urlParams);

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                success: function (data) {
                    $replaceProduct.empty().html(data);
                },
                error: function (err) {
                    //test
                }
            });
        });

        $('body').on('shown.bs.modal', '#recommendationModal', function () {
            $('body').css('padding-right', 0)
        });

        $('body').on('hidden.bs.modal', '#recommendationModal', function () {
            $('body').removeClass('recommendation-opened')
        });

        $(document).on('click', '.modal-header .close-button', function () {
            $('#recommendationModal').removeClass('show');
            $('#recommendationModal').modal('hide');
            $('body').removeClass('recommendation-opened')
        })
    }
};