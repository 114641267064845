'use strict';

var slickConfigs = require('../config/slickConfigs');
var zoomConfigs = require('../config/zoomConfigs');
var zoom = require('jquery-zoom');
var imagesloaded = require('imagesloaded');
var focusHelper = require('base/components/focus');
var overlay = require('./overlay');
var base = require('./base');
var Extend = window.Extend || undefined;
var dataLayerGTM = require('gtm/gtmDataLayer');

/**
 * Update Images in Tile after swatch click
 */
function handleVariantResponseTile(response, $productContainer, target) {
    var isChoiceOfBonusProducts =
    $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
    }

    // Update primary images PDP
    var primaryImageUrls = response.product.images.square;
    // createCarousel(primaryImageUrls, $productContainer);

    if (!isChoiceOfBonusProducts) {
        // Update pricing
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);

        // Update title and Description
        var $productTitle = $productContainer.find('.product-details h2.product-name');
        $productTitle.replaceWith('<h2 class="product-name hidden-sm-down">' + response.product.productName + '</h2>');
        var $productURL = $productContainer.find('.product-details .product-name-link');
        $productURL.attr('href', response.product.selectedProductUrl);
        $productTitle.replaceWith('<a class="product-name hidden-sm-down">' + response.product.productName + '</h2>');
        var $tileDescription = $productContainer.find('.product-details .tileDescription');
        if (response.product.tileDescription) {
            $tileDescription.html(response.product.tileDescription);
        } else {
            $tileDescription.empty();
        }
        var $selectedSizeOverlayProduct = $productContainer.find('.product-details .selectedSizeOverlayProduct');
        if (response.product.selectedSize) {
            $selectedSizeOverlayProduct.html(response.product.selectedSize);
        } else {
            $selectedSizeOverlayProduct.empty();
        }

        // Update images
        var $productImages = $productContainer.find('.overlay-product-image');
        $productImages.replaceWith(response.product.productOverlayImagesHtml);
    }

    // Update Add To Cart button
    var $addToCartBtnHtml = $productContainer.find('.add-product-tile-js');

    // update sticky header select box
    if (typeof target !== "undefined" && target.hasClass('sticky-select')) {
        $addToCartBtnHtml.html($(response.product.addToCartBtnHtml).find('.sticky-add-to-cart-container').addClass('active'));
        var selector = $('.sticky-select');
        var selectedAttrTValue = selector.find(":selected").data('attr-value');
        var mainSelect = $('.attribute .select-size')
        mainSelect.find('option').removeAttr('selected')
        mainSelect.find(`[data-attr-value='${selectedAttrTValue}']`).attr('selected', true)
    } else {
        //$addToCartBtnHtml.replaceWith(response.product.addToCartBtnHtml);
    }

    // Update badge of promotion
    if (response.product && response.product.badgeObj) {
        var badgeObj = response.product.badgeObj;
        var promoBadgeNew = "<div class='promo-badge' style='color:" + badgeObj.badgePdpPriceTextColor + "; background-color:" + badgeObj.badgePdpPriceColor + "'>" + badgeObj.badgePdpPriceText + "</div>";
        $productContainer.find('.promo-badge').remove();
        $productContainer.find('.prices').append(promoBadgeNew);
    } else {
        $productContainer.find('.promo-badge').remove();
    }

}

function updateImagesQuickview(productType, images, appendToElement, videoUrl, isThumbnails) {
    var isThumbnails = isThumbnails || false;
    var videoImage = false;
    var count = 0;
    for (var i = 0; i < images.length; i++) {
        if (images[i].title.toUpperCase() === 'VIDEOIMAGE' || images[i].url.match(/[^\/]+(?=\.(gif|jpeg|jpg|png))/)[0].toUpperCase() === 'VIDEOIMAGE') {
            videoImage = true;
        }
        count++;
    }
    // To render Hero image in product-gallery as first image
    for (var i = 0; i < images.length; i++) {
        if (images[i].title.toUpperCase() === 'HERO' || images[i].url.match(/[^\/]+(?=\.(gif|jpeg|jpg|png))/)[0].toUpperCase() === 'HERO') {
            $('<div class="product-image-item zoom-image"><img src="' + images[i].url + '" class="quickview-image" alt="' + images[i].alt + ' image number ' + parseInt(images[i].index, 10) + '" title="' + images[i].title + '" itemprop="image" /></div>').appendTo($(appendToElement));
        }
    }
    // To render all existing images except hero and videoImage image in product-gallery
    for (var i = 0; i < images.length; i++) {
        if (images[i].title.toUpperCase() !== 'HERO' && images[i].url.match(/[^\/]+(?=\.(gif|jpeg|jpg|png))/)[0].toUpperCase() !== 'HERO' && images[i].title.toUpperCase() !== 'VIDEOIMAGE' && images[i].url.match(/[^\/]+(?=\.(gif|jpeg|jpg|png))/)[0].toUpperCase() !== 'VIDEOIMAGE') {
            $('<div class="product-image-item zoom-image"><img src="' + images[i].url + '" class="quickview-image" alt="' + images[i].alt + ' image number ' + parseInt(images[i].index, 10) + '" title="' + images[i].title + '" itemprop="image" /></div>').appendTo($(appendToElement));
        }
    }
    // To render videoImage/video in product-gallery as last item
    for (var i = 0; i < images.length; i++) {
        if (images[i].title.toUpperCase() === 'VIDEOIMAGE' || images[i].url.match(/[^\/]+(?=\.(gif|jpeg|jpg|png))/)[0].toUpperCase() === 'VIDEOIMAGE') {
            if (isThumbnails) {
                $('<div class="product-image-item zoom-image"><img src="' + images[i].url + '" class="quickview-image" alt="' + images[i].alt + ' image number ' + parseInt(images[i].index, 10) + '" title="' + images[i].title + '" itemprop="image" /></div>').appendTo($(appendToElement));
            } else {
                if (videoUrl !== null && videoImage) {
                    $('<div class="product-image-item video"><video aria-hidden="true" autoplay="" loop="" muted="" playsinline="" height="500" poster="' + images[i].url + '" width="500" class="quickview-video-item"><source src="' + videoUrl + '" type="video/mp4"><img alt="" src=""></video></div>').appendTo($(appendToElement));
                } else {
                    $('<div class="product-image-item zoom-image"><img src="' + images[i].url + '" class="quickview-image" alt="' + images[i].alt + ' image number ' + parseInt(images[i].index, 10) + '" title="' + images[i].title + '" itemprop="image" /></div>').appendTo($(appendToElement));
                }
            }
        }
    }
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
 function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of all product images, along with related information
 * @param {Object} response - response from Ajax call
 * @param {Object} response.productGalleryVideoURL - Product object
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function quickviewCreateCarousel(imgs, $productContainer, response) {
    var quickViewImageUrls = imgs.quickview;
    var quickViewNavImageUrls = imgs.quickviewnav;
    var quickviewCarousel = $productContainer.find('.quickview-gallery');
    var quickviewCarouselNav = $productContainer.find('.quickview-gallery-nav');
    var videoUrl = response.productGalleryVideoURL;
    var productType = response.product.productType;

    if ($('#quickViewModal.show').length > 0) {
        $('#quickViewModal .quickview-gallery-nav .slick-slide[data-slick-index]').off('click');
        if (quickviewCarousel.length && quickviewCarousel.hasClass('slick-initialized')) {
            quickviewCarousel.slick('unslick');
        }
        if (quickviewCarouselNav.length && quickviewCarouselNav.hasClass('slick-initialized')) {
            quickviewCarouselNav.slick('unslick');
        }

        $(quickviewCarousel).html('');
        updateImagesQuickview(productType, quickViewImageUrls, quickviewCarousel, videoUrl);
        $(quickviewCarouselNav).html('');
        updateImagesQuickview(productType, quickViewNavImageUrls, quickviewCarouselNav, videoUrl, true);
        $(quickviewCarouselNav).attr('data-image-items', $(quickviewCarouselNav).find('.product-image-item').length);
        if ($(quickviewCarouselNav).find('.product-image-item').length < 2) {
            $(quickviewCarouselNav).html('');
        }

        imagesloaded(quickviewCarousel).on('always', function () {
            $(quickviewCarousel).slick(slickConfigs.quickview);
        });
        imagesloaded(quickviewCarouselNav).on('always', function () {
            if ($(quickviewCarouselNav).find('.product-image-item').length > 1) {
                $(quickviewCarouselNav).slick(slickConfigs.quickviewNav);
                $(quickviewCarouselNav).find('.slick-slide[data-slick-index]').on('click', function(e) {
                    e.preventDefault();
                    $(this).siblings().removeClass('slick-active-item');
                    $(this).addClass('slick-active-item');
                    var slideno = $(this).data('slick-index');
                    $(quickviewCarousel).slick('slickGoTo', slideno);
                });
                $(quickviewCarouselNav).find('.slick-slide').eq(0).addClass('slick-active-item');
            }
        });
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;
    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else if ($('.upsell-bundle-item').length && $('.upsell-bundle-item').data('item')) {
        pid = $('.upsell-bundle-item').data('item');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Update Images in Tile after swatch click
 */
function handleVariantResponseTileOverlay(response, $productContainer, target) {
    var isChoiceOfBonusProducts =
    $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
    }

    // Update primary images PDP
    var primaryImageUrls = response.product.images.square;
    // createCarousel(primaryImageUrls, $productContainer);

    if (!isChoiceOfBonusProducts) {
        // Update pricing
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);

        // Update title and Description
        var $productTitle = $productContainer.find('.product-details h2.product-name');
        $productTitle.replaceWith('<h2 class="product-name hidden-sm-down">' + response.product.productName + '</h2>');
        var $productURL = $productContainer.find('.product-details .product-name-link');
        $productURL.attr('href', response.product.selectedProductUrl);
        $productTitle.replaceWith('<a class="product-name hidden-sm-down">' + response.product.productName + '</h2>');
        var $tileDescription = $productContainer.find('.product-details .tileDescription');
        if (response.product.tileDescription) {
            $tileDescription.html(response.product.tileDescription);
        } else {
            $tileDescription.empty();
        }
        var $selectedSizeOverlayProduct = $productContainer.find('.product-details .selectedSizeOverlayProduct'),
        $selectedColorOverlayProduct = $productContainer.find('.product-details .selectedColorOverlayProduct');

        if (response.product.selectedColor) {
            $selectedColorOverlayProduct.html(response.product.selectedColor);
        }
        if (response.product.selectedSize) {
            $selectedSizeOverlayProduct.html(response.product.selectedSize);
        } else {
            $selectedSizeOverlayProduct.empty();
        }

        // Update images
        var isCartPage = $('.page').hasClass('cart-show-content');
        var $productImages = $productContainer.find('.overlay-product-image');
        if (isCartPage) {
            $productImages.replaceWith(response.product.productRecommendationImagesHtml);
        } else {
            $productImages.replaceWith(response.product.productOverlayImagesHtml);
        }
    }

    // Update Add To Cart button
    var $addToCartBtnHtml = $productContainer.find('.add-product-overlay');
    $addToCartBtnHtml.find('.overlay-add-to-cart-url').attr('data-pid', response.product.id);

    // Update badge of promotion
    if (response.product && response.product.badgeObj) {
        var badgeObj = response.product.badgeObj;
        var promoBadgeNew = "<div class='promo-badge' style='color:" + badgeObj.badgePdpPriceTextColor + "; background-color:" + badgeObj.badgePdpPriceColor + "'>" + badgeObj.badgePdpPriceText + "</div>";
        $productContainer.find('.promo-badge').remove();
        $productContainer.find('.prices').append(promoBadgeNew);
    } else {
        $productContainer.find('.promo-badge').remove();
    }

}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, target) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if(!$productContainer.hasClass('overlay-product')) {
        if (response.product.variationAttributes) {
            updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
            isVaraint = response.product.productType === 'variant';
            if (isChoiceOfBonusProducts && isVaraint) {
                $productContainer.parent('.bonus-product-item')
                    .data('pid', response.product.id);
    
                $productContainer.parent('.bonus-product-item')
                    .data('ready-to-order', response.product.readyToOrder);
            }
        }
    
        if (response.product.availability.shippingMsg) {
            $productContainer.find('.shipping-info').removeClass('shipping-info--out-stock').addClass('shipping-info--in-stock');
            $productContainer.find('.shipping-info-msg').text(response.product.availability.shippingMsg);
        } else {
            $productContainer.find('.shipping-info').addClass('shipping-info--out-stock').removeClass('shipping-info--in-stock');
            $productContainer.find('.shipping-info-msg').text('');
        }
    
        if ($('.quickview-dialog.show').length > 0) {
            // Update primary images Quickview
            var ImageUrls = response.product.images;
            quickviewCreateCarousel(ImageUrls, $productContainer, response);
        } else {
            // Update primary images PDP
            var primaryImageUrls = response.product.images.large;
            // createCarousel(primaryImageUrls, $productContainer);
        }
    
        if (!isChoiceOfBonusProducts) {
            // Update pricing
            var $priceSelector = $('.prices .price', $productContainer).length
                ? $('.prices .price', $productContainer)
                : $('.prices .price');
            $priceSelector.replaceWith(response.product.price.html);
    
            // Update title
            var $productTitle = $('.product-details h1.product-name');
            $productTitle.replaceWith('<h1 class="product-name hidden-sm-down">' + response.product.productName + '</h1>');

            // update product Variant if exits
            var $productVariant = $('.product-details span.price-from-text');
            $productVariant.replaceWith('<span class="price-from-text">' + response.product.selectedSize + '</span>');
    
            // Update images
            var $productImages = $('.product-hero-image-wrapper');
            $productImages.replaceWith(response.product.productImagesHtml);
        }
    
        // Update Add To Cart button
        var $addToCartBtnHtml = $('.add-to-cart-button-wrapper');
    
        // update sticky header select box
        if (typeof target !== "undefined" && target.hasClass('sticky-select')) {
            var responseHtml = $(response.product.addToCartBtnHtml);
            var activeSticky = responseHtml.find('.sticky-add-to-cart-container').addClass('active');
            responseHtml.remove('.sticky-add-to-cart-container');
            var finalHtml = responseHtml.insertAfter('.cart-and-ipay' ,activeSticky);
            $addToCartBtnHtml.html(finalHtml);
            var selector = $('.sticky-select');
            var selectedAttrTValue = selector.find(":selected").data('attr-value');
            var mainSelect = $('.attribute .select-size')
            mainSelect.find('option').removeAttr('selected')
            mainSelect.find(`[data-attr-value='${selectedAttrTValue}']`).attr('selected', true)
        } else {
            $addToCartBtnHtml.replaceWith(response.product.addToCartBtnHtml);
        }

        // Update Reviews
        if ($('.review-prices').length > 0) {
            $('.review-prices').html($('.product-details .pdp-price').clone());
        }
    
        // Update promotions
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    
        // Update badge of promotion
        if (response.product && response.product.badgeObj) {
            var badgeObj = response.product.badgeObj;
            var promoBadgeNew = "<div class='promo-badge' style='color:" + badgeObj.badgePdpPriceTextColor + "; background-color:" + badgeObj.badgePdpPriceColor + "'>" + badgeObj.badgePdpPriceText + "</div>";
            $productContainer.find('.promo-badge').remove();
            $productContainer.find('.prices').append(promoBadgeNew);
        } else {
            $productContainer.find('.promo-badge').remove();
        }

        // Update Size display name on alternate Upsell
        if ($('.alternate-upsell-group').length > 0 && $('.alternate-upsell-size-value').length > 0 && (response.product.alternateUpsellLeftHTML || response.product.alternateUpsellRightHTML)) {
            var isLeftAlligned = $($('.upsell-item')[0]).hasClass('current-item');

            if (isLeftAlligned) {
                $('.alternate-upsell-container').html(response.product.alternateUpsellLeftHTML);
            } else {
                $('.alternate-upsell-container').html(response.product.alternateUpsellRightHTML);
            }
        }
    
        updateAvailability(response, $productContainer);
    
        if (isChoiceOfBonusProducts) {
            var $selectButton = $productContainer.find('.select-bonus-product');
            $selectButton.trigger('bonusproduct:updateSelectButton', {
                product: response.product, $productContainer: $productContainer
            });
        } else {
            // Enable "Add to Cart" button if all required attributes have been selected
            $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                product: response.product, $productContainer: $productContainer
            }).trigger('product:statusUpdate', response.product);
        }
    
        // Update attributes
        $productContainer.find('.main-attributes').empty()
            .html(getAttributesHtml(response.product.attributes));
    } else {
        handleVariantResponseTileOverlay(response, $productContainer, target);
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponseQuickView(response, $productContainer, target) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if(!$productContainer.hasClass('overlay-product')) {
        if (response.product.variationAttributes) {
            updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
            isVaraint = response.product.productType === 'variant';
            if (isChoiceOfBonusProducts && isVaraint) {
                $productContainer.parent('.bonus-product-item')
                    .data('pid', response.product.id);
    
                $productContainer.parent('.bonus-product-item')
                    .data('ready-to-order', response.product.readyToOrder);
            }
        }
    
        if (response.product.availability.shippingMsg) {
            $productContainer.find('.shipping-info').removeClass('shipping-info--out-stock').addClass('shipping-info--in-stock');
            $productContainer.find('.shipping-info-msg').text(response.product.availability.shippingMsg);
        } else {
            $productContainer.find('.shipping-info').addClass('shipping-info--out-stock').removeClass('shipping-info--in-stock');
            $productContainer.find('.shipping-info-msg').text('');
        }
    
        if ($('.quickview-dialog.show').length > 0) {
            // Update primary images Quickview
            var ImageUrls = response.product.images;
            quickviewCreateCarousel(ImageUrls, $productContainer, response);
        } else {
            // Update primary images PDP
            var primaryImageUrls = response.product.images.large;
            // createCarousel(primaryImageUrls, $productContainer);
        }
    
        if (!isChoiceOfBonusProducts) {
            // Update pricing
            var $priceSelector = $productContainer.find('.prices .price');
            $priceSelector.replaceWith(response.product.price.html);
    
            // Update title
            var $productTitle = $productContainer.find('.product-details h1.product-name');
            $productTitle.replaceWith('<h1 class="product-name hidden-sm-down">' + response.product.productName + '</h1>');

            // update product Variant if exits
            var $productVariant = $productContainer.find('.product-details span.price-from-text');
            $productVariant.replaceWith('<span class="price-from-text">' + response.product.selectedSize + '</span>');
    
            // Update images
            var $productImages = $productContainer.find('.product-hero-image-wrapper');
            $productImages.replaceWith(response.product.productImagesHtml);
        }
    
        // Update Add To Cart button
        var $addToCartBtnHtml = $productContainer.find('.cart-and-ipay');
        $addToCartBtnHtml.replaceWith($(response.product.addToCartBtnHtml).find('.cart-and-ipay'));

        // Update promotions
        $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);
    
        // Update badge of promotion
        if (response.product && response.product.badgeObj) {
            var badgeObj = response.product.badgeObj;
            var promoBadgeNew = "<div class='promo-badge' style='color:" + badgeObj.badgePdpPriceTextColor + "; background-color:" + badgeObj.badgePdpPriceColor + "'>" + badgeObj.badgePdpPriceText + "</div>";
            $productContainer.find('.promo-badge').remove();
            $productContainer.find('.prices').append(promoBadgeNew);
        } else {
            $productContainer.find('.promo-badge').remove();
        }

    
        updateAvailability(response, $productContainer);
    
        if (isChoiceOfBonusProducts) {
            var $selectButton = $productContainer.find('.select-bonus-product');
            $selectButton.trigger('bonusproduct:updateSelectButton', {
                product: response.product, $productContainer: $productContainer
            });
        } else {
            // Enable "Add to Cart" button if all required attributes have been selected
            $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                product: response.product, $productContainer: $productContainer
            }).trigger('product:statusUpdate', response.product);
        }
    
        // Update attributes
        $productContainer.find('.main-attributes').empty()
            .html(getAttributesHtml(response.product.attributes));
    }
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
	// Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Updates the color label text with Product-Variant Ajax call
 * @param {Array[]} variationAttributes - array of variation attributes
 */
function updateColorLabel(variationAttributes, $productContainer) {
    if (variationAttributes && variationAttributes.length > 0) {
        var colorAttribute = variationAttributes.filter(item => item.attributeId === 'color');
        if (colorAttribute && colorAttribute.length > 0) {
            var attrValues = colorAttribute[0].values;
            if (attrValues && attrValues.length > 0) {
                var selectedColor = attrValues.filter(item => item.selected);
                if (selectedColor && selectedColor.length > 0) {
                    var newColorString = colorAttribute[0].displayName + ': ' + selectedColor[0].displayValue;

                    $productContainer.find('span' + '.' + colorAttribute[0].attributeId).text(newColorString);
                }
            }
        }
    }
}


function getParameterValue(url, name) {
    let urlObj = new URL(url);
    let params = new URLSearchParams(urlObj.search);
    let paramName = Array.from(params.keys()).find(key => key.endsWith(name));
    let value = paramName ? params.get(paramName) : null;
    return value;
}

// function to check absolute URL
function isAbsoluteURL(url) {
    return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
}

function updateURLWithParams(variantURL, productContainer) {
    // Get the canonical tag
    var canonicalTag = document.querySelector('link[rel="canonical"]');
    let $productContainer = $(productContainer);
    if (!variantURL || !isAbsoluteURL(variantURL) || $productContainer.hasClass('product-quickview') || $productContainer.hasClass('bundle-detail') || canonicalTag == null) {
        return;
    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let size = getParameterValue(variantURL, '_size');
    let color = getParameterValue(variantURL, '_color');

    // Remove query parameters from the current URL
    let urlWithoutParams = url.origin + url.pathname;

    // Only add parameters if the URL without parameters matches the canonical URL
    if (urlWithoutParams === canonicalTag.href) {
        if (size) {
            params.set('size', size);
        }
        if (color) {
            params.set('color', color);
        }
        url.search = params.toString();
        window.history.replaceState({}, '', url.toString());
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer, target) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });
        var isQuickView = $productContainer.hasClass('product-quickview') || false;
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {

                if (isQuickView) {
                    handleVariantResponseQuickView(data, $productContainer, target);
                    updateQuantities(data.product.quantities, $productContainer);
                    updateColorLabel(data.product.variationAttributes, $productContainer);
                    $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
                } else {
                    handleVariantResponse(data, $productContainer, target);
                    updateOptions(data.product.optionsHtml, $productContainer);
                    updateQuantities(data.product.quantities, $productContainer);
                    updateColorLabel(data.product.variationAttributes, $productContainer);
                    updateBXObject(data);
                    upgradePriceModule(data);
                    handleCompareUpsell(data.product.selectedSize, data.product.selectedColor);
                    updateURLWithParams(selectedValueUrl, $productContainer);
                    $('body').trigger('gtm:pdpUpdateVariant', data.gtmPdpData);
                    $('body').trigger('product:afterAttributeSelect',
                        { data: data, container: $productContainer });
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

// upgrade price for mini upgrade module
function upgradePriceModule(data) {
    if ((data.product.upgradePrice) && $('.upgrade-price').length) {
        $('.upgrade-price').html(data.product.upgradePrice);
        $('.pdp__miniupgrade-modul').removeClass('d-none');

    }

    if ((!data.product.upgradePrice) && $('.upgrade-price').length || (data.product.upgradePrice === 'Free')) {
        $('.pdp__miniupgrade-modul').addClass('d-none');
    }
}

/**
 * update window.bx_selected_product object on attribute change on PDP
 * @param {Object} data - product object used to fill data
 */
function updateBXObject(data) {
    var bxSelectedProduct = window.bx_selected_product;
    if (bxSelectedProduct) {
        if (data.product.variationAttributes && data.product.variationAttributes.length > 0) {
            var sizeAttribute = data.product.variationAttributes.filter(item => item.attributeId === 'size');
            var colorAttribute = data.product.variationAttributes.filter(item => item.attributeId === 'color');
            if (colorAttribute && colorAttribute.length > 0) {
                bxSelectedProduct.color = colorAttribute[0].displayValue.toLowerCase().replace(/\s+|[,\/]/g, "-");
            }
        
            if (sizeAttribute && sizeAttribute.length > 0) {
                bxSelectedProduct.size = sizeAttribute[0].displayValue.toLowerCase().replace(/\s+|[,\/]/g, "-");
            }
        }

        if (data.product.id) {
            bxSelectedProduct.sku = data.product.id;
        }

        if (data.product.price.sales) {
            bxSelectedProduct.price = data.product.price.sales.value;
        }
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getMiniCartViewUrl() {
    return $('.get-mini-cart-view').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Init the product main carousel using a predefined slick configuration
 */
function carouselInit() {
    var $carousel = $('.product-gallery');
    var vw = window.innerWidth;
    if ($carousel.length) {
        if (vw > 1023) {
            if ($carousel.length && $carousel.hasClass('slick-initialized')) {
                $carousel.slick('unslick');
            }
        } else {
            imagesloaded($carousel).on('always', function () {
                $carousel.not('.slick-initialized').slick(slickConfigs.pdpGallery);
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 100);
            });
        }
    }
}

/**
 * Deconstruct (unslick) main the carousel, removing classes and handlers added on slick initialize.
 */
function carouselUnslick() {
    var $carousel = $('.product-gallery');

    if ($carousel.length && $carousel.hasClass('slick-initialized')) {
        $carousel.slick('unslick');
    }
}

/**
 * Disable PDP Zoom
 */
function disableZoom() {
    $('.zoom-image .product-image-wrapper').trigger('zoom.destroy');
    $('.product-gallery').removeClass('zoom-initialized');
    $('.zoom-image .product-image-wrapper').find('.zoomImg').remove();
}

/**
 * Init PDP Zoom
 */
function initZoom() {
    disableZoom();
    var vw = window.innerWidth;
    var zoomImage = $('.zoom-image .product-image-wrapper');
    if (vw > 1023) {
        zoomImage.each(function() {
            $(this).zoom(zoomConfigs.pdpZoom);
        });
        zoomImage.closest('.product-gallery').addClass('zoom-initialized');
    }
}

/**
 * Zoom event listeners for product gallery image.
 */
function imageActions() {
    carouselInit();
    $(window).on('resize', function () {
        var vw = window.innerWidth;
        var zoomInitialized = $('.product-gallery.zoom-initialized');
        var heroImage = $('.product-image-item.hero-image .product-main-image');
        var heroImageDesktop = $('.product-image-item.hero-image .product-main-image.desktop-image');
        var heroImageMobile = $('.product-image-item.hero-image .product-main-image.mobile-image');
        var heroImageMobileUrl = heroImage.data('mobile');
        var heroImageDesktopUrl = heroImage.data('desktop');
        var mainCarousel = $('.product-gallery');
        
        if (vw > 1023) {
            if (heroImageMobile.length > 0) {
                heroImage.removeClass('mobile-image');
                heroImage.addClass('desktop-image');
                heroImage.attr('src', heroImageDesktopUrl);
            }
            if (mainCarousel.length && mainCarousel.hasClass('slick-initialized')) {
                mainCarousel.slick('unslick');
            }
            if (zoomInitialized.length === 0) {
                setTimeout(function () {
                    initZoom();
                    $('.product-gallery').addClass('zoom-initialized');
                }, 500);
            }
        } else {
            disableZoom();
            if (heroImageDesktop.length > 0) {
                heroImage.removeClass('desktop-image');
                heroImage.addClass('mobile-image');
                heroImage.attr('src', heroImageMobileUrl);
            }
            if (mainCarousel.length && !mainCarousel.hasClass('slick-initialized')) {
                carouselInit();
            }
        }
    });
}

/**
 * Gallery modal event listeners for product gallery video item.
 */
function galleryModalActions() {
    $(document).on('click', '.product-video-button.desktop-button', function () {
        $('.modal-layer').addClass('show');
    });
    $(document).on('click', '.modal-layer .close', function () {
        $('.modal-layer').removeClass('show');
    });
    $(document).on('click','.modal.product-gallery-video-modal .close', function () {
        $('.modal-layer').removeClass('show');
    });

    $(window).on('resize', function () {
        var vw = window.innerWidth;
        if (vw > 1023) {
            if ($('.modal.product-gallery-video-modal.show').length > 0) {
                $('.modal.product-gallery-video-modal .close').trigger('click');
                $('.modal-layer').addClass('show');
            }
        } else {
            if ($('.modal-layer.show').length > 0) {
                $('.product-video-button.mobile-button').trigger('click');
                $('.modal-layer').removeClass('show');
            }
        }
    });
}

function enableButtonOnLoad() {
    $(document).ready(function() {
        $('.ready').removeAttr('disabled');
    })
}

$(window).on('resize', function(){
    checkModalHeight();
});

// check modal height depends on header position
function checkModalHeight() {
    if ($('#recommendationModal').length > 0) {
        var wOffset = $(window).scrollTop();
        var header = $('header .header-wrapper');
        var headerHeight = header.height();


        $('#recommendationModal').find('.modal-body').height(
            $(window).height() - 
            $('.recommendation-modal').find('.modal-header').outerHeight() - 
            $('.recommendation-modal').find('.modal-footer').outerHeight() -
            ($(window).width() >= 768 ? $('header').height() : 0) - 20
        );

        if (wOffset > 0 && wOffset < headerHeight && header.css('position') !== 'fixed') {
            var position = headerHeight - wOffset;
            $('.modal-backdrop').css('top', headerHeight+position);
            $('.recommendation-modal.show').css('top', headerHeight+position);

            $('#recommendationModal').find('.modal-body').height(
                $(window).height() - 
                $('.recommendation-modal').find('.modal-header').outerHeight() - 
                $('.recommendation-modal').find('.modal-footer').outerHeight() -
                ($(window).width() >= 768 ? $('header').height() : 0) + 20
            );
        }

        if (wOffset > 0 && wOffset > headerHeight && header.css('position') !== 'fixed') {
            $('.modal-backdrop').css('top', 0);
            $('.recommendation-modal.show').css('top', 0);

            $('#recommendationModal').find('.modal-body').height(
                $(window).height() - 
                $('.recommendation-modal').find('.modal-header').outerHeight() - 
                $('.recommendation-modal').find('.modal-footer').outerHeight() - 20
            );
        }
    }
}

function checkEinsteinRecommendations() {
    setTimeout(function () {
        if ($('.overlay-product-recommendations').length === 0) {
            $('.overlay-products-fallback').removeClass('d-none');
        }
    }, 2000);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(data) {
    if ($('#recommendationModal').length !== 0) {
        $('#recommendationModal').remove();
    }
    $('body').append(data);
}

    /**
 * Show cart modal window on the first call.
 * @param {string} data - representing the body and footer of the modal window
 * @param {string} pid - Product ID which was added
 */
function showCartModal(data, pid) {
    if (data) {
        $('body > .modal-backdrop').remove();
        var data = {
            pid: pid
        }
        var miniCartViewUrl = getMiniCartViewUrl();
        $.ajax({
            url: miniCartViewUrl,
            method: 'GET',
            data: data,
            dataType: "html",
            success: function (data) {
                if (data) {
                    getModalHtmlElement(data);
                    $('#recommendationModal').modal('show');
                    checkModalHeight();
                    $('body').addClass('recommendation-opened');
                    checkEinsteinRecommendations();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
            complete: function () {
                $('body').trigger('postAtcOverlay:opened');
            }
        });
    }
}

function handleCompareUpsell(size, color) {
    const $pdpCompareUpsellDiv = $('.pdp-compare');
    if ($pdpCompareUpsellDiv.length && (size || color)) {
        var heading = $('.pdp-compare_headline').text();
        var subHeading = $('.pdp-compare_subheadline').text();
        var url = $pdpCompareUpsellDiv.attr('data-url');
        var displaySections = $pdpCompareUpsellDiv.attr('data-display-sections');
        var currentProduct = $pdpCompareUpsellDiv.attr('data-current-product');
        var upgradeProductMasterID = $pdpCompareUpsellDiv.attr('data-upgrade-product');

        var queryStringArr = ['displaySections=' + displaySections, 'currentProduct=' + currentProduct, 'upgradeProductMasterID=' + upgradeProductMasterID];
        
        if (!!heading) {
            queryStringArr.push('heading=' + heading);
        }

        if (!!subHeading) {
            queryStringArr.push('subHeading=' + subHeading);
        }

        if (!!size) {
            queryStringArr.push('size=' + size);
        }

        if (!!color) {
            queryStringArr.push('color=' + color);
        }

        $.ajax({
            url: url + '?' + queryStringArr.join('&'),
            method: 'GET',
            success: function success(data) {
                if (!data.error && data.compareUpsellHTML) {
                    $pdpCompareUpsellDiv.parent().html(data.compareUpsellHTML);
                    $('body').trigger('product:loadRatingReviewSummary');
                }

                return;
            },
            error: function error() {
                return;
            }
        });
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    updateAttrs: updateAttrs,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled') || $(this).find('.selected').length) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"]:not(.select-size-compare), .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer, $(this));
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $('body').addClass('pdp-veil').trigger('product:beforeAddToCart', this);
            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }
            // BEGIN Extend integration
            var EXT_PDP_UPSELL_SWITCH = window.EXT_PDP_UPSELL_SWITCH || undefined;
            var isPlanSelected = false;
            form.area = 'product_page';
            form.component = 'buttons';
            if ($('#extend-offer').length) {
                var extendComponent = Extend.buttons.instance('#extend-offer');
                var extendPlan = extendComponent.getPlanSelection();
                if (extendPlan) {
                    form.extendPlanId = extendPlan.planId;
                    form.extendPrice = extendPlan.price;
                    form.extendTerm = extendPlan.term;
                    isPlanSelected = true;
                    $(this).trigger('updateAddToCartFormData', form);

                    var extendData = {
                        'id': 'EXTEND-' + extendPlan.term,
                        'extendTerm': extendPlan.term,
                        'price': extendPlan.price,
                        'qty': 1,
                        'pid': pid,
                        'atcLocation': 'PDP - ATC'
                    };
                    dataLayerGTM.extendAddToCartEvent(extendData);
                }
            }
            // END Extend integration

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        miniCartReportingUrl(data.reportingURL);
                        if (data.overlayData && !data.error) {
                            overlay.initialize();
                            showCartModal(data, pid);
                            $.spinner().stop();
                        } else {
                            $.spinner().stop();
                            window.location.href = data.redirectURL;
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                        $('body').removeClass('pdp-veil');
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    enableButtonOnLoad: enableButtonOnLoad,
    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,

    initZoom: initZoom(),
    carouselUnslick: carouselUnslick,
    carouselInit: carouselInit,
    imageActions: imageActions(),
    galleryModalActions: galleryModalActions(),
    handlePostCartAdd: handlePostCartAdd
};
