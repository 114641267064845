'use strict';


var modal = {
    /**
     * Generates the modal window on the first call.
     * @param {string} modalID The modal ID used to generate the modal
     */
    getModalHtmlElement: function (modalID) {
        var $modal = $('#' + modalID);
        if ($modal.length !== 0) {
            $modal.remove();
        }

        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade" id="' + modalID + '" tabindex="-1" role="dialog">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog">'
            + '<div class="modal-content">'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
    },


    /**
     * replaces the content in the modal.
     * @param {string} url - url to be used to retrieve a new content
     * @param {string} modalID - modal ID to be used
     */
    fillModalElement: function (url, modalID) {
        $('.modal-content').spinner().start();
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                var $modal = $('#' + modalID);
                $modal.find('.modal-content').empty();
                $modal.find('.modal-content').html(data);
                var $modalTitle = $modal.find('h2.content-title');
                if ($modal.attr('id') === 'free-shipping' && $modalTitle.length) {
                    $modalTitle.attr('id', 'free-shipping-title');
                    $modal.attr('aria-labelledby', 'free-shipping-title');
                }
                // $modal.modal('show');
                $.spinner().stop();
            }
        });
    }
};

module.exports = modal;
