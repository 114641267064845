'use strict';

var cart = require('../cart/cart');
var dataLayerGTM =require('gtm/gtmDataLayer');

var updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
 function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
 function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
 function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotalWithoutDiscounts);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
 function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            if (count.quantityTotal > 0) {
                $('.minicart .minicart-quantity').removeClass('d-none');
            } else {
                $('.minicart .minicart-quantity').addClass('d-none');
            }
        }
    });

    $('body').on('click', '.promo-code-minicart-submit',function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        var $form = $this.closest('.promo-code-form-minicart');
        var inputCode = $this.closest('.form-group').find('.coupon-code-minicart-field');
        if (!$(inputCode).val()) {
            $('.promo-code-form-minicart .form-control').addClass('is-invalid');
            $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        $('.promo-code-form-minicart .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize()
        })
            .done(function(data) {
                if (data.error) {
                    $('.promo-code-form-minicart .form-control').addClass('is-invalid');
                    $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    var deliveryMethod = $(inputCode).val();
                    var event ='apply_promo_code';
                    dataLayerGTM.methodApply(event, deliveryMethod);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-minicart-field').val('');
                $.spinner().stop();
            })
            .fail(function(err) {
                $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
            });
        return false;
    });

    $('body').on('click', '.remove-coupon-minicart', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $productToRemoveSpan.empty().append(couponCode);

        var url = $(this).closest('.coupns-container').find('.removeCouponMinicart').data('remove-coupon');

        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });


    // $('.minicart .icon-container').on('click', function () {

    //     var url = $('.minicart').data('action-url');
    //     var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    //     if (count !== 0 && $('.minicart .popover.show').length === 0) {
    //         if (!updateMiniCart) {
    //             $('.minicart .popover').addClass('show');
    //             return;
    //         }

    //         $('.minicart .popover').addClass('show');
    //         $('.minicart .popover').spinner().start();
    //         $.get(url, function (data) {
    //             $('.minicart .popover').empty();
    //             $('.minicart .popover').append(data);
    //             updateMiniCart = true;
    //             $.spinner().stop();
    //         });
    //         var urlEvent = window.location.href;
    //         var event ='click_mini_cart_open';
    //         dataLayerGTM.navigationClick(event, urlEvent);
    //     }
    // });

    
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('click', '.close-mini-cart', function () {
        $('.minicart .popover').removeClass('show');
        var url = window.location.href;
        var event ='click_mini_cart_close';
        dataLayerGTM.navigationClick(event, url);
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
