'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
  
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkEthycaFidesCookie(dataSalesAndSharing) {
    var data = {
        ethycaFides: dataSalesAndSharing !== 'undefined' ? !dataSalesAndSharing : true
    };

    var urlSetCookie = $('#setEthycaFidesCookie').val();
    $.ajax({
        url: urlSetCookie,
        method: 'GET',
        data: data,
        success: function (data) {
            if (data) {
                setCookie('ethycaFidesTurnedOff', dataSalesAndSharing, 365);
            }
        },
        error: function (err) {
            window.console.log('EthycaFidesCookie wasn`t set on SFCC')
        }
    });
}

/**
 * reload html header for Pinterest
 */
function reloadHtmlHeaderPinterest () {
    var pinterestData = $('#idPinterestHelper');
    var urlReloadHtml = $('#idPinterestHelperURL').val();
    if (pinterestData.length > 0 && urlReloadHtml){
        var data = JSON.parse(pinterestData.val());
        $.ajax({
            url: urlReloadHtml,
            method: 'GET',
            data: data,
            success: function (data) {
                $("body").append(data);
            },
            error: function (err) {
                window.console.log('Pinteres html header wasn`t set on page')
            }
        });
    }
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var input = $('input[name=hpEmailSignUp]');

    if ($('.email-signup-message').length === 0) {
        button.closest('.form-input-group').after(
           '<div class="email-signup-message"></div>'
        );
        $('.email-signup-alert').remove();
        $('.email-signup-message')
        .append('<div class="email-signup-alert" id="email-signup-alert">' + data.msg + '</div>');

        $('.form-input-group')
        .append('<div class="email-signup-message hidden"><div class="email-signup-alert" id="email-signup-alert-hidden">' + data.msg + '</div></div>');

        const isError = data.msg.toLowerCase().indexOf('success') < 0;
        if (isError) {
            $('body').trigger('gtmErrorTracking', {errorType: 2, errorMessage: data.msg});
        }
    }

    if (data.error) {
        input.attr('aria-invalid', true)
    }

    input.blur();
    input.attr('aria-describedby', 'email-signup-alert').focus();

    setTimeout(function () {
        button.removeAttr('disabled');
    }, 3000);
}

function updateFooterToCurrentYear() {
    var currentYear = new Date();
    $('.copyright-wrapper').find('.copyright').html('&copy;' + currentYear.getFullYear());
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        var pageSource = $('.page.homepage').length ? 'homepage' : location.pathname
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                pageSource: pageSource
            },
            success: function (data) {
                displayMessage(data, button);
                if (!data.error) {
                    $('body').trigger('gtm:newsletter_subscription');
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('input[name=hpEmailSignUp]').on('input', function () {
        $('.email-signup-message').remove();
        $(this).removeAttr('aria-invalid')
    });

    $(document).ready(function() {
        // initiate dynamicYield with ethyca status
        var dataSalesAndSharing = window.Fides.consent.data_sales_and_sharing;
        var ethycaFidesTurnedOff = getCookie('ethycaFidesTurnedOff');

        if(ethycaFidesTurnedOff !== String(dataSalesAndSharing)) {
            checkEthycaFidesCookie(dataSalesAndSharing);
        }
        if (window.DYEnabled && dataSalesAndSharing !== undefined) {
            window.setOptanonCookie(dataSalesAndSharing);
        }
        updateFooterToCurrentYear();
    });

    $('body').on('click', '.fides-modal-button-group .fides-banner-button-primary, .fides-modal-button-group .fides-banner-button-secondary', function(){
        var dataSalesAndSharing = window.Fides.consent.data_sales_and_sharing;
        var ethycaFidesTurnedOff = getCookie('ethycaFidesTurnedOff');
        if(ethycaFidesTurnedOff !== String(dataSalesAndSharing)) {
            checkEthycaFidesCookie(dataSalesAndSharing);
            if(dataSalesAndSharing) {
                reloadHtmlHeaderPinterest();
            }
        }
    });

    // intercept window.dataLayer to update DY status when ethyca third party modal is triggered
    window.dataLayer.push = function (...items) {
        if (items[0].event === 'FidesUpdated') {
            var dataSalesAndSharing = items[0].Fides.consent.data_sales_and_sharing;
            window.setOptanonCookie(dataSalesAndSharing);
        }

        Array.prototype.push.apply(this, items);
    }
};
