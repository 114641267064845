'use strict';

const modal = require('./modal');
const currentUrl = window.location.href;

const Selectors = {
    slideUp: '.slide-up',
    headerWrapper: '.header-wrapper',
    body: 'body',
    panelItem: '.panel-item',
    serviceBarItem: '.service-bar-item',
    dropdownLink: '.dropdown-link',
    seeAllButton: '.see-all-button',
    dropdownContainer: '.dropdown-container',
    dropdownContentImage: '.dropdown-content-image',
    contentWrapper: '.content-wrapper',
    headerNavLink: '.js-header-nav-link'
};

/**
 * Function to handle panel item click event
 * @param {Event} event - The click event object
 */
function handlePanelItemClick(event) {
    event.preventDefault();
    const href = $(this).data('href');
    modal.getModalHtmlElement('free-shipping');
    modal.fillModalElement(href, 'free-shipping');
}

/**
 * Function to handle the dropdown link focus event
 */
function dropdownLinkFocus() {
    const $this = $(this);

    if ($this.closest(Selectors.headerWrapper).length > 0) {
        const id = $this.attr('data-id');
        const $list = $this.closest(Selectors.dropdownContainer).children(Selectors.dropdownContentImage).children(Selectors.contentWrapper);

        $list.each(function() {
            const $content = $(this);
            $content.toggleClass('d-none', id !== $content.data('id'));
        });
    }
}

/**
 * Function to handle the category nav active state
 */
function navActiveState() {
    if($(Selectors.headerNavLink).length > 0) {
        $(Selectors.headerNavLink).each(function() {
            $(this).toggleClass('nav-link--active', currentUrl.includes($(this).attr('href')));
        });
    }
}

module.exports = {
    initEvents: () => {
        $(Selectors.body).on('click', `${Selectors.panelItem}, ${Selectors.serviceBarItem}`, handlePanelItemClick);
        $(Selectors.body).on('mouseenter', `${Selectors.dropdownLink}, ${Selectors.seeAllButton}`, dropdownLinkFocus);
        $(document).ready(navActiveState);
    }
};
