window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var tagManager = require('gtm/tagManager');

$(document).ready(function () {
    tagManager.init('storefront');
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/slickSlider'));
    processInclude(require('./components/header'));
    processInclude(require('./components/tooltip'));
    processInclude(require('./pagedesigner/index'));
    processInclude(require('./product/tile'));
    processInclude(require('./components/formEvents'));
    processInclude(require('./friendbuy/init'));
    processInclude(require('./components/tailwind'));
    processInclude(require('./components/bvReviews'));

    if (window.EXT_GLOBAL_SWITCH) {
        processInclude(require('./extend/cart'));
        processInclude(require('extend_sfra/extend/extendAnalytics'));
    }
});

require('./thirdParty/bootstrap');
require('slick-carousel');
require('./loader');

