'use strict';
var slickConfigs = require('../config/slickConfigs');

/**
 * Init globally reusable carousels
 */

module.exports = {
    productTileCarousels: function () {
        $('.js-product-tile-caro').slick(slickConfigs.productTiles);
        $('.js-product-tile-caro-pd').each(function() {
            var defaultConfig = slickConfigs.productTilesPD;
            var mobileOptions = $(this).closest('.pd-carousel').data('mobile');
            var tabletOptions = $(this).closest('.pd-carousel').data('tablet');
            var desktopOptions = $(this).closest('.pd-carousel').data('desktop');
            defaultConfig.slidesToShow = desktopOptions.slidesToShow;
            defaultConfig.arrows = desktopOptions.arrows;
            defaultConfig.dots = desktopOptions.dots;
            defaultConfig.infinite = desktopOptions.infinite;
            defaultConfig.responsive[0].settings.slidesToShow = tabletOptions.slidesToShow;
            defaultConfig.responsive[0].settings.arrows = tabletOptions.arrows;
            defaultConfig.responsive[0].settings.dots = tabletOptions.dots;
            defaultConfig.responsive[1].settings.slidesToShow = mobileOptions.slidesToShow;
            defaultConfig.responsive[1].settings.arrows = mobileOptions.arrows;
            defaultConfig.responsive[1].settings.dots = mobileOptions.dots;

            $(this).slick(defaultConfig);
        });
    },

    reviewsCarousels: function () {
            $('.js-review-carousel-pd').each(function() {
            var defaultConfig = slickConfigs.reviews;
            var mobileOptions = $(this).closest('.pd-carousel').data('mobile');
            var tabletOptions = $(this).closest('.pd-carousel').data('tablet');
            var desktopOptions = $(this).closest('.pd-carousel').data('desktop');
            defaultConfig.slidesToShow = desktopOptions.slidesToShow;
            defaultConfig.dots = desktopOptions.dots;
            defaultConfig.responsive[0].settings.slidesToShow = tabletOptions.slidesToShow;
            defaultConfig.responsive[0].settings.arrows = tabletOptions.arrows;
            defaultConfig.responsive[0].settings.dots = tabletOptions.dots;
            defaultConfig.responsive[1].settings.slidesToShow = mobileOptions.slidesToShow;
            defaultConfig.responsive[1].settings.arrows = mobileOptions.arrows;
            defaultConfig.responsive[1].settings.dots = mobileOptions.dots;

            $(this).slick(defaultConfig);
        });
    },
};
