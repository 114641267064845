'use strict';
var input = '.float-labels input:not([type="checkbox"],[type="radio"],[type="hidden"]), .float-labels select, .float-labels textarea, .half-float-labels input:not([type="checkbox"],[type="radio"],[type="hidden"]), .half-float-labels select, .half-float-labels textarea, #braintreePaypalAccountsList';

module.exports = function () {
    // add class focus on input wrapper when focused
    $(document).on('focus', input, function () {
        $(this).closest('.form-group').addClass('focused');
        var currentLabelWidth = $(this).closest('.form-group').find('label').width();
        $(this).closest('.form-group').find('.input-border').css({'width': currentLabelWidth.toFixed(0) + 'px'});
        if($('.form-group').hasClass('focused')) {
            $('form-group').removeClass('has-text');
        }
    });

    // remove class focused on input wrapper when input not focused
    $(document).on('blur', input, function () {
        $(this).closest('.form-group').removeClass('focused');
        $(input).each(function () {
            var val = $(this).val();
            var isInputVisible = $(this).is(':visible');
            if (val !== null && isInputVisible) {
                if (val.length > 0) {
                    $(this).closest('.form-group').addClass('has-text');
                    $(input).closest('.form-group').removeClass('focused');
                } else {
                    $(this).closest('.form-group').removeClass('has-text');
                }
            }
        });
        var currentLabelWidth = $(this).closest('.form-group').find('label').width();
        $(this).closest('.form-group').find('.input-border').css({'width': currentLabelWidth + 'px'});
    });

    // update input class on input change
    $(document).on('change', input, function () {
        var val = $(this).val();
        if (val !== null) {
            if(val.length > 0) {
                $(this).closest('.form-group').addClass('has-text');
            } else {
                $(this).closest('.form-group').removeClass('has-text');
            }
        }
    });

    // check input if value or autofill is exist
    $(input).each(function () {
        var val = $(this).val();
        var currentLabelWidth = $(this).closest('.form-group').find('label').width();

        if (val !== null) {
            if (val.length > 0 || $(this).is(":-webkit-autofill")) {
                $(this).closest('.form-group').addClass('has-text');
            }
        }
        if (currentLabelWidth > 0) {
            $('<div class="input-border" style="width:' + currentLabelWidth + 'px;"></div>').insertAfter(this);
        } else {
            $('<div class="input-border" style="width:' + (-1 * (currentLabelWidth)) + 'px;"></div>').insertAfter(this);
        }
    });

    $('.return-item textarea').on('input', function(e) {
        var $this = $(this).attr('id');
        $('.' + $this).html($(this).val().length);
    });
}