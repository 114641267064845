'use strict';

/**
 * Default Zoom config
 */
module.exports = {
    pdpZoom: {
        url: false,
        on: 'click',
        duration: 120,
        target: false,
        touch: true,
        magnify: 1,
        callback: function () {
            $(this).parent().on('mouseout', function() {
                $(this).parent().trigger('click');
            });
        }
    }
};
